import axios from "axios";
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setData, setCurrentIndex, setIsFetching, setIsFilterLoading } from '../redux/slices/deckSlice';

const useMoreCards = (selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset, expandSearch, setOffset, setLastPreference) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value);

  const handleMoreCards = debounce(async (preference, buttonType, callback) => {
    const selectedGenreIds = Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);

    dispatch(setIsFetching(true));
    dispatch(setIsFilterLoading(true));
    setOffset(offset + limit);
    setLastPreference(preference);

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios.get(
      `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&preference=${preference}&filters=${selectedGenreIds.join(",")}${expandSearch ? "&expand_search=true" : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating[]=${rating.source}&rating_value[]=${rating.ratingValue}`).join('') : ""}&selected_libraries=${selectedPlexLibraries.join(",")}&selected_collections=${selectedCollections.join(",")}`,
      config
    )
      .then((response) => {
        const { contents, metadata } = response.data;

        if (metadata.no_results) {
          dispatch(setData([]));
          // Handle no results case, e.g., show a message or set a flag
        } else {
          dispatch(setData(contents));
        }

        dispatch(setIsFetching(false));
        dispatch(setIsFilterLoading(false));
        dispatch(setCurrentIndex(0));
        callback();
      })
      .catch((error) => {
        dispatch(setIsFetching(false));
        dispatch(setIsFilterLoading(false));
        console.error(error);
      });
  }, 500);

  return { handleMoreCards };
};

export default useMoreCards;
