import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../assets/UserPreferences.css';
import plexLogo from '../assets/images/plex-logo.png';

const UserPreference = ({ saveUserPreferences }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);

  const [availableProviders, setAvailableProviders] = useState([]);
  const [savedProviders, setSavedProviders] = useState([]);
  const [saving, setSaving] = useState(false);

  const formatProviderName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '_');
  };

  const getProviderLogo = (provider) => {
    if (provider.provider_name.toLowerCase() === 'plex') {
      return plexLogo;
    } else if (provider.source === 'tmdb' && provider.logo_path) {
      return `https://image.tmdb.org/t/p/original${provider.logo_path}`;
    } else if (provider.source === 'rapid_api' && provider.imageSet) {
      return provider.imageSet.whiteImage || provider.imageSet.lightThemeImage || provider.imageSet.darkThemeImage;
    } else if (provider.logo_path) {
      return provider.logo_path;
    }
    return null; // No logo available
  };

  const fetchUserPreferences = async () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user_preferences`, config);
      if (response.data && response.data.status === 'success' && response.data.user_preference) {
        return response.data.user_preference.streaming_providers || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error);
      return [];
    }
  };

  const fetchAvailableProviders = async () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/streaming_providers`, config);
      const providers = response.data.providers || {};

      return Object.values(providers).map(provider => ({
        provider_name: provider.name,
        logo_path: provider.logo_path,
        imageSet: provider.imageSet,
        source: provider.source,
        themeColorCode: provider.themeColorCode
      }));
    } catch (error) {
      console.error('Error fetching available providers:', error);
      return [];
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!user || !token) {
        return;
      }

      const userPreferences = await fetchUserPreferences();
      setSavedProviders(userPreferences);

      const providers = await fetchAvailableProviders();

      // Add Plex to available providers if it's not already in saved providers or available providers
      if (!userPreferences.some(p => p.provider_name.toLowerCase() === 'plex') &&
          !providers.some(p => p.provider_name.toLowerCase() === 'plex')) {
        providers.push({
          provider_name: 'Plex',
          logo_path: plexLogo,
          source: 'custom',
          themeColorCode: '#282a2d'
        });
      }

      setAvailableProviders(providers.filter(provider =>
        !userPreferences.some(savedProvider => savedProvider.provider_name.toLowerCase() === provider.provider_name.toLowerCase())
      ));
    }
    fetchData();
  }, [user, token]);

  const handleAddProvider = async (provider) => {
    setSaving(true);
    const formattedProvider = {
      provider_name: formatProviderName(provider.provider_name),
      logo_path: getProviderLogo(provider),
      themeColorCode: provider.themeColorCode,
      source: provider.source
    };

    const updatedProviders = [...savedProviders, formattedProvider];
    setSavedProviders(updatedProviders);
    setAvailableProviders(availableProviders.filter(p => p.provider_name.toLowerCase() !== formattedProvider.provider_name.toLowerCase()));

    try {
      await saveUserPreferences(updatedProviders);
    } catch (error) {
      console.error('Error saving user preferences:', error);
      // Revert the state if save fails
      setSavedProviders(savedProviders);
      setAvailableProviders([...availableProviders, provider]);
    }

    setSaving(false);
  };

  const handleRemoveProvider = async (provider) => {
    setSaving(true);
    const updatedProviders = savedProviders.filter(p => p.provider_name.toLowerCase() !== provider.provider_name.toLowerCase());
    setSavedProviders(updatedProviders);

    // When removing, we need to add the provider back to availableProviders
    const removedProvider = availableProviders.find(p => p.provider_name.toLowerCase() === provider.provider_name.toLowerCase()) || provider;
    setAvailableProviders([...availableProviders, removedProvider]);

    try {
      await saveUserPreferences(updatedProviders);
    } catch (error) {
      console.error('Error saving user preferences:', error);
      // Revert the state if save fails
      setSavedProviders(savedProviders);
      setAvailableProviders(availableProviders.filter(p => p.provider_name.toLowerCase() !== provider.provider_name.toLowerCase()));
    }

    setSaving(false);
  };

  const renderProviderContent = (provider) => {
    const logoUrl = getProviderLogo(provider);
    return (
      <div className="provider-logo-container" style={{ backgroundColor: provider.themeColorCode || '#f0f0f0' }}>
        {logoUrl ? (
          <img src={logoUrl} data-testid={provider.provider_name} alt={provider.provider_name} />
        ) : (
          <span className="provider-name">{provider.provider_name}</span>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className='provider-header'>
        <h2>Available Streaming Services</h2>
        <p>Select the streaming services you want to see content from.</p>
      </div>
      {availableProviders.length > 0 ? (
        <div className="provider-grid">
          {availableProviders.map((provider, index) => (
            <div
              key={index}
              className="provider-logo"
              onClick={!saving ? () => handleAddProvider(provider) : null}
            >
              {renderProviderContent(provider)}
            </div>
          ))}
        </div>
      ) : (
        <div className="no-providers-message">
          No streaming services currently available for selection. Please check back soon!
        </div>
      )}
      {savedProviders.length > 0 && (
        <>
          <div className='provider-header'>
            <h2>Your Selected Streaming Services</h2>
          </div>
          <div className="provider-grid">
            {savedProviders.map((provider, index) => (
              <div
                key={index}
                className="provider-logo saved"
              >
                {renderProviderContent(provider)}
                <div
                  className="remove-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!saving) handleRemoveProvider(provider);
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default UserPreference;
