// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heart-icon {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 4em;
  color: #bbbbbbca;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.hearted {
  color: #ff7c62;
  animation: heart-pulse 1s ease-in-out;
}

@keyframes heart-pulse {
  0% { transform: translate(-50%, 50%) scale(1); }
  50% { transform: translate(-50%, 50%) scale(1.1); }
  100% { transform: translate(-50%, 50%) scale(1); }
}
`, "",{"version":3,"sources":["webpack://./src/assets/HeartButton.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,+BAA+B;EAC/B,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,KAAK,wCAAwC,EAAE;EAC/C,MAAM,0CAA0C,EAAE;EAClD,OAAO,wCAAwC,EAAE;AACnD","sourcesContent":[".heart-icon {\n  position: absolute;\n  bottom: 10%;\n  left: 50%;\n  transform: translate(-50%, 50%);\n  font-size: 4em;\n  color: #bbbbbbca;\n  cursor: pointer;\n  transition: color 0.3s ease-in-out;\n}\n\n.hearted {\n  color: #ff7c62;\n  animation: heart-pulse 1s ease-in-out;\n}\n\n@keyframes heart-pulse {\n  0% { transform: translate(-50%, 50%) scale(1); }\n  50% { transform: translate(-50%, 50%) scale(1.1); }\n  100% { transform: translate(-50%, 50%) scale(1); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
