import { useState, useEffect } from "react";
import axios from "axios";

const useSwipeActions = (token, data, currentIndex, dispatch, setData, setCurrentIndex) => {
  const [swipeCounter, setSwipeCounter] = useState(0);
  const [swipedCards, setSwipedCards] = useState([]);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [swipeContentId, setSwipeContentId] = useState(null);

  const postSwipeAction = async () => {
    if (!token || !swipeDirection) return;

    const item = data.find(i => i.id === swipeContentId || i.ratingKey === swipeContentId);
    if (!item) return;

    let endpoint;
    if (item.source === "plex") {
      endpoint = `${process.env.REACT_APP_API_URL}/${swipeDirection === 'like' ? 'liked_plex_contents' : 'disliked_plex_contents'}`;
    } else {
      endpoint = `${process.env.REACT_APP_API_URL}/${swipeDirection === 'like' ? 'liked_contents' : 'disliked_contents'}`;
    }

    try {
      await axios.post(endpoint, {
        content_id: swipeContentId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });
    } catch (error) {
      console.error(error);
    }

    setSwipeDirection(null);
  };

  useEffect(() => {
    postSwipeAction();
  }, [token, swipeDirection, data]);

  const undoLastSwipe = async () => {
    if (swipedCards.length === 0) return;

    const lastSwipedCard = swipedCards[swipedCards.length - 1];
    const newSwipedCards = swipedCards.slice(0, -1);

    setSwipedCards(newSwipedCards);

    let fetchedCard;
    if (lastSwipedCard.source === 'db') {
      fetchedCard = await axios.get(`${process.env.REACT_APP_API_URL}/contents/card/${lastSwipedCard.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });
    } else if (lastSwipedCard.source === 'plex') {
      fetchedCard = await axios.get(`${process.env.REACT_APP_API_URL}/contents/card/${lastSwipedCard.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: {
          source: 'plex'
        }
      });
    }

    if (fetchedCard) {
      dispatch(setData([{ ...fetchedCard.data, source: lastSwipedCard.source }, ...data]));
      dispatch(setCurrentIndex(currentIndex));
    }
  };

  return { swipeCounter, setSwipeCounter, swipedCards, setSwipedCards, swipeDirection, setSwipeDirection, swipeContentId, setSwipeContentId, undoLastSwipe };
};

export default useSwipeActions;
