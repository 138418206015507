import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import placeholderImage from '../assets/images/flickswipe_logo.png';
import SavedContentDetail from "./SavedContentDetail";
import '../assets/SavedContent.css';
import { useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

const SavedContent = () => {
    const user = useSelector((state) => state.user.userData);
    const token = useSelector((state) => state.token.value);

    const [savedContents, setSavedContents] = useState([]);
    const [userId, setUserId] = useState(user?.id);
    const [selectedContentId, setSelectedContentId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageUrls, setImageUrls] = useState({});
    const [plexDetails, setPlexDetails] = useState({});
    const [singlePlexItem, setSinglePlexItem] = useState({});
    const [singlePlexThumb, setSinglePlexThumb] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [error, setError] = useState(null);
    const [allProviders, setAllProviders] = useState({});
    const [country, setCountry] = useState('');

    useEffect(() => {
        if (userId) {
            fetchSavedContents();
        }
    }, [userId]);

    useEffect(() => {
        if (user) {
            setUserId(user.id);
        }
    }, [user]);

    const fetchSavedContents = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/users/${userId}/saved_contents`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );

            if (response.data.contents && Array.isArray(response.data.contents)) {
                setSavedContents(response.data.contents);
                setAllProviders(response.data.all_providers || {});
                setCountry(response.data.country || '');

                // Pre-fetch image URLs for each content
                const fetchedImageUrls = await Promise.all(response.data.contents.map(async content => {
                    const url = await getImageUrl(content);
                    return { id: content.id, url };
                }));

                // Convert the array of image URLs to an object for easier access
                const imageUrlMap = fetchedImageUrls.reduce((acc, curr) => {
                    acc[curr.id] = curr.url;
                    return acc;
                }, {});
                setImageUrls(imageUrlMap);
            } else {
                console.error("Received unexpected data format from the server:", response.data);
                setError("Received unexpected data format from the server.");
            }
        } catch (error) {
            console.error('Failed to fetch saved contents', error);
            setError("Failed to fetch saved contents. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
      const fetchPlexDetails = async () => {
          const plexContents = savedContents.filter(content => content.source === 'plex');
          for (const content of plexContents) {
              if (!plexDetails[content.rating_key]) {
                  await fetchPlexItemDetails(content.rating_key);
              }
          }
      };

      if (savedContents.length > 0) {
          fetchPlexDetails();
      }
    }, [savedContents]);

    const fetchPlexItemDetails = async (ratingKey) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/contents/card/${ratingKey}?source=plex`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            const data = response.data;
            setPlexDetails(prevDetails => ({ ...prevDetails, [ratingKey]: data }));
            return data;
        } catch (error) {
            console.error('Failed to fetch Plex item details', error);
            return null;
        }
    };

    const fetchSinglePlexItemDetails = async (ratingKey) => {
        setLoadingDetails(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/contents/card/${ratingKey}?source=plex`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            const data = response.data;
            setSinglePlexItem(data);
            return data;
        } catch (error) {
            console.error('Failed to fetch Plex item details', error);
            return null;
        } finally {
            setLoadingDetails(false);
        }
    };

    const fetchPlexThumbnail = async (thumb) => {
        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/plex_media/thumbnail?thumb_path=${encodeURIComponent(thumb)}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });
            let blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Failed to fetch Plex thumbnail:", error);
            return null;
        }
    };

    const fetchSinglePlexThumbnail = async (thumb) => {
        setLoadingDetails(true);
        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/plex_media/thumbnail?thumb_path=${encodeURIComponent(thumb)}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });
            let blob = await response.blob();
            setSinglePlexThumb(URL.createObjectURL(blob))
        } catch (error) {
            console.error("Failed to fetch Plex thumbnail:", error);
            return null;
        } finally {
            setLoadingDetails(false);
        }
    };

    const getImageUrl = async (content) => {
        if (content.source === 'plex') {
            const plexItemDetails = await fetchPlexItemDetails(content.rating_key);
            if (plexItemDetails && plexItemDetails.thumb) {
                return await fetchPlexThumbnail(plexItemDetails.thumb);
            }
            return placeholderImage;
        } else if (content.source === 'db' || content.source === null) {
            return content.poster_path;
        } else {
            return placeholderImage;
        }
    };

    const getTitle = (savedContent) => {
      if (savedContent.source === 'plex') {
          const plexDetail = plexDetails[savedContent.rating_key];
          return plexDetail ? plexDetail.title : 'Loading Plex Title...';
      } else {
          return savedContent.title || savedContent.name || 'Unknown Title';
      }
    };

    const handleDelete = async (content_id) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/users/${userId}/saved_contents/${content_id}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            setSavedContents(prevContents => prevContents.filter(content => content.id !== content_id));
        } catch (error) {
            console.error('Failed to delete saved content', error);
        }
    };

    const handleClick = async (contentId) => {
        const selectedContent = savedContents.find(content => content.id === contentId);
        setSinglePlexItem({});
        setSinglePlexThumb(null);

        if (selectedContent.source === 'plex') {
            const details = await fetchSinglePlexItemDetails(selectedContent.rating_key);
            if (details && details.thumb) {
                await fetchSinglePlexThumbnail(details.thumb);
            }
        }
        setSelectedContentId(contentId);
    };

    if (!userId) {
        return <p>Loading...</p>;
    }
    const isPlexContent = savedContents.source === 'plex';

    return (
      <div className='savedContentContainer'>
          <h1 className='savedHeader'>Saved Media</h1>
          {error && <p className="error-message">{error}</p>}
          {selectedContentId && (
              <SavedContentDetail
                  attributes={savedContents.find(content => content.id === selectedContentId)}
                  plexDetails={singlePlexItem}
                  plexThumb={singlePlexThumb || imageUrls[selectedContentId]}
                  closeDetail={() => setSelectedContentId(null)}
                  token={token}
                  loadingDetails={loadingDetails}
                  location_preference={user.location_preference.toLowerCase()}
                  allProviders={allProviders}
                  country={country}
              />
          )}
          {loading ? (
              <div className="loader-container">
                  <ClipLoader
                      loading={loading}
                      size={50}
                      color="#ff7c62"
                  />
              </div>
          ) : (
              <div className='saved-card-container'>
                  {savedContents.map(savedContent => (
                      <div key={savedContent.id} className="saved-content-card" onClick={() => handleClick(savedContent.id)}>
                          <img src={imageUrls[savedContent.id] || placeholderImage} alt={getTitle(savedContent) || 'Content'} />
                          <h2>{getTitle(savedContent)}</h2>
                          <FontAwesomeIcon icon={faTimes} className="saved-delete-icon" onClick={(e) => { e.stopPropagation(); handleDelete(savedContent.id); }} />
                      </div>
                  ))}
              </div>
          )}
      </div>
  );
};

export default SavedContent;
