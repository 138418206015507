import React, { useState } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import '../../assets/ForgottenPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const requestPasswordReset = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    axios
  .post(`${process.env.REACT_APP_API_URL}/users/forgot_password`, { email })
  .then((response) => {
    if (response.data.status === 'ok') {
      setSuccessMessage('A password reset link has been sent to your email');
      setErrorMessage('');
      setIsEmailSent(true);
    } else {
      setErrorMessage(response.data.message);
    }
  })
  .catch((err) => {
    if (err.response && err.response.status === 404) {
      setErrorMessage('Email not found');
    } else {
      setErrorMessage('Error sending password reset email');
    }
  })
  .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="forgot-password-container">
      {isSubmitting ? (
        <ClipLoader loading={isSubmitting} size={50} color="#ff7c62" />
      ) : isEmailSent ? (
        <div className="success-message">{successMessage}</div>
      ) : (
        <form className="forgot-password-form" onSubmit={requestPasswordReset}>
          <h2>Forgot Password</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Request Password Reset'}
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      )}
    </div>
  );
}

export default ForgotPassword;
