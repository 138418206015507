const UserRecentSearches = ({ recentSearches, handleRecentSearchClick }) => {
  return (
    <div className="recentSearches">
      <h3>Recent Searches</h3>
      <div className="searchGrid">
        {recentSearches.slice(-4).map((search, index) => (
          <div data-testid="search-item" key={index} className="searchItem" onClick={() => handleRecentSearchClick(search)}>
            <span className="searchItemText">{search.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserRecentSearches;
