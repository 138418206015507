import React, { useState } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

import '../../assets/TwoFactorAuth.css'

export default function TwoFactorAuth({ user_id, onVerification, totpSecret }) {
  const [totpCode, setTotpCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const [copied, setCopied] = useState(false);



  function handleVerifyTotp(event) {
    event.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/update_verify_totp`, { user_id, totp_code: totpCode }, { withCredentials: true })
      .then((response) => {
        if (response.data.status === "success") {
        onVerification(true); // Call the onVerification prop after successful verification
        } else {
          onVerification(false);
        }
      })
      .catch((error) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data.message;
          setInvalidCode(errorMessage);
        } else {
          errorMessage = "Unknown error";
          console.log(error);
          onVerification(false);
        }
        onVerification(false, errorMessage); // Call the onVerification prop with false and error message on error
      });
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(totpSecret.secret).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };


  return (
    <div className="auth-verification-container">
      {totpSecret && (
        <div className="totp-secret-container">
          <p>Copy and paste the code below into your authenticator app if you cannot scan the QR code.</p>
          <div
            className={`totpSecret secret-box${copied ? " copied" : ""}`}
            onClick={copyToClipboard}
          >
            <span className="secret-content">{!copied ? totpSecret.secret : 'Copied'}</span>
            <FontAwesomeIcon icon={faClipboard} style={{ marginLeft: '8px' }} />
          </div>
        </div>
      )}
      <form className="twofactorauth-form" onSubmit={handleVerifyTotp}>
        <h2>Verify</h2>
        <p>(Enter a one time password from your authenticator)</p>
        <input
          type="text"
          name="totpCode"
          placeholder=""
          onChange={(e) => setTotpCode(e.target.value)}
        />
        <button onClick={() => (setInvalidCode(false))} type="submit">Verify</button>
        { invalidCode &&
          <h4 className="twofactorauth-error">{invalidCode}</h4>
        }
      </form>
    </div>
  );
}
