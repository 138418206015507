import axios from "axios";
import { PlexOauth } from "plex-oauth";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/userSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';
import PlexUpdateDetails from "./PlexUpdateDetails";

import plexLogo from '../../assets/images/plex-logo.png'

import '../../assets/PlexLoginComponent.css'

function PlexLoginComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hostedUILink, setHostedUILink] = useState('');
  const [pinId, setPinId] = useState(null);
  const [isIncompleteRegistration, setIncompleteRegistration] = useState(false);
  const [plexUserId, setPlexUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authenticationProcessed, setAuthenticationProcessed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordResetLink, setPasswordResetLink] = useState('');
  const [emailTaken, setEmailTaken] = useState(false);
  const [plexUserEmail, setPlexUserEmail] = useState("")


  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const clientInformation = {
    clientIdentifier: "www.flickswipe.org",
    product: "Flickswipe",
    device: "Web App",
    version: "1",
    forwardUrl: `${process.env.REACT_APP_PLEX_REDIRECT_URL}/process-plex-login`,
    platform: "Web",
    urlencode: true
  }

  const plexOauth = new PlexOauth(clientInformation);

  const handleLogin = async () => {
    try {
      const [link, pin] = await plexOauth.requestHostedLoginURL();
      setHostedUILink(link);
      setPinId(pin);
      localStorage.setItem('pinId', pin);
      window.location.href = link;
    } catch (error) {
      console.error("Error fetching hosted UI URL:", error);
    }
  };

  const handlePlexAuthenticated = async () => {
    setLoading(true);
    try {
      const storedPinId = localStorage.getItem('pinId');
      console.log('Retrieved stored pinId:', storedPinId);

      if (!storedPinId) {
        console.error('No pinId found in localStorage');
        return;
      }

      console.log('Making auth request to:', `${process.env.REACT_APP_API_URL}/auth/plex`);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/plex`,
        { pin_id: storedPinId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      );

      console.log('Auth response:', response.data);

      setPlexUserEmail(response.data.email);
      localStorage.removeItem('pinId');

      if (response.data.status === 'incomplete_registration') {
        setIncompleteRegistration(true);
        setPlexUserId(response.data.plex_id);
        setPlexUserEmail(response.data.email);
      } else if (response.data.status === 'plex_not_linked') {
        setEmailTaken(true);
        setPlexUserId(response.data.plex_id);
        setPlexUserEmail(response.data.email);
      } else {
        dispatch(setUser(response.data.user));
        dispatch(setToken(response.data.jwt));
        dispatch(setLoggedIn(true));
        dispatch(setSubscriptionStatus(response.data.user.subscription_status));
        dispatch(setSubscriptionEndDate(response.data.user.subscription_end_date));
        dispatch(setCancelAtPeriodEnd(response.data.user.cancel_at_period_end));
        sessionStorage.setItem('userData', JSON.stringify(response.data.user));
        sessionStorage.setItem('token', response.data.jwt);
        sessionStorage.setItem('refresh_token', response.data.refresh_token);
        setAuthenticationProcessed(true);
      }
    } catch (error) {
      console.error("Detailed auth error:", error.response || error);
      setErrorMessage(error.response?.data?.message || "Authentication failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const shouldAuthenticate = !loggedIn && window.location.href.includes('/process-plex-login') && localStorage.getItem('pinId');

  useEffect(() => {
    let isMounted = true;
    const performAuthentication = async () => {
      if (shouldAuthenticate && isMounted) {
        await handlePlexAuthenticated();
        setAuthenticationProcessed(true);
      }
    }
    performAuthentication();
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isIncompleteRegistration || emailTaken) {
    return <PlexUpdateDetails plexUserEmail={plexUserEmail} emailTaken={emailTaken} onDetailsUpdated={() => {
      setIncompleteRegistration(false);
      setEmailTaken(false);
      navigate("/");
    }} />;
  };

  return (
    <div>
      {!authenticationProcessed && (
        <button className="plex-login-btn" onClick={handleLogin}>
          <img src={plexLogo} alt="Plex Logo" className="plex-logo" />
          Login with Plex
        </button>
      )}
      {errorMessage && (
      <div className="error-message">
        <p>{errorMessage}</p>
        {passwordResetLink && (
          <a href={passwordResetLink}>Click here to reset password</a>
        )}
      </div>
      )}
    </div>
  );
}

export default PlexLoginComponent;
