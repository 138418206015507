import React from 'react';
import { animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const LikeIndicator = ({ style }) => (
  <animated.div
    className="like-dislike-indicator"
    style={{
      ...style,
      position: 'absolute',
      top: '50%',
      left: '50%',
      fontSize: '10rem',
      color: '#28a745',
      userSelect: 'none',
      zIndex: 5,
      pointerEvents: 'none',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <FontAwesomeIcon icon={faThumbsUp} />
  </animated.div>
);

export default LikeIndicator;
