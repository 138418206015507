import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/NotificationAlert.css';

const NotificationAlert = ({ message, visible, onClose }) => {
  const countdownRef = useRef(null);
  const [hiding, setHiding] = useState(false);

  useEffect(() => {
    if (visible) {
      setHiding(false);
      countdownRef.current.style.width = '100%'; // Reset the countdown bar

      const timer = setTimeout(() => {
        setHiding(true);
        setTimeout(onClose, 300); // Wait for the fade-out transition before calling onClose
      }, 8000); // The alert will close automatically after 3 seconds


      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  return (
    <div role="alert" className={`notification-alert ${visible ? 'show' : ''} ${hiding ? 'hide' : ''}`}>
      <div className="scrolling-text">
        <Link to="/deck">
          <p>{message}</p>
        </Link>
      </div>
      <div className="countdown" ref={countdownRef}></div>
    </div>
  );
};

export default NotificationAlert;
