import { useSpring } from "@react-spring/web";

const useSwipeIndicators = () => {
  const [likeProps, setLike] = useSpring(() => ({ opacity: 0, scale: 1 }));
  const [dislikeProps, setDislike] = useSpring(() => ({ opacity: 0, scale: 1 }));

  const handleIndicators = (down, xDir) => {
    const likeOpacity = xDir > 0 && down ? 25 : 0;
    const dislikeOpacity = xDir < 0 && down ? 25 : 0;
    const flashDuration = 2;
    setTimeout(() => {
      setLike({ opacity: likeOpacity, scale: likeOpacity ? 1.2 : 1 });
      setDislike({ opacity: dislikeOpacity, scale: dislikeOpacity ? 1.2 : 1 });
    }, flashDuration);
  };

  return { likeProps, dislikeProps, handleIndicators };
};

export default useSwipeIndicators;
