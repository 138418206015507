import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import ClipLoader from 'react-spinners/ClipLoader';
import '../assets/ConfirmAccount.css'

export default function ConfirmAccount() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/users/confirm/${token}`)
        .then((response) => {
          setLoading(false);
          setConfirmationMessage(response.data.message);
          navigate('/login');
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.data.message) {
            setConfirmationMessage(error.response.data.message);
          } else {
            setConfirmationMessage('An error occurred during confirmation.');
          }
        });
    } else {
      setConfirmationMessage('Invalid token');
    }
  }, [token, navigate]);

  return (
    <div className="confirm-account-container">
      <div className="confirming-account">
        {confirmationMessage || 'Confirming account...'}
        {loading && (
          <ClipLoader
            loading={loading}
            size={50}
            color="#ff7c62"
          />
        )}
      </div>
    </div>
  );
}
