// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripe-subscription {
  text-align: center;
  padding: 12px;
  color: var(--text-primary);
  max-height: calc(100vh - 150px);
  overflow: auto;
}

.process-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--secondary-background);
  width: 175px;
  height: 175px;
  border-radius: 10px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 30%;
}

.process-loader p {
  color: var(--theme-consistent);
}

.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.success-message p {
  margin: 0;
}

.cancel-information {
  color: var(--text-primary);
}

.cancelEndDate {
  font-weight: bolder;
}

.error-message {
  color: red;
  font-weight: bold;
  background-color: #f0d8d8;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.subInfo {
  color: var(--text-primary);
}

.subscription-details {
  color: var(--text-primary);
}
`, "",{"version":3,"sources":["webpack://./src/assets/StripeSubscription.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,0BAA0B;EAC1B,+BAA+B;EAC/B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,6CAA6C;EAC7C,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".stripe-subscription {\n  text-align: center;\n  padding: 12px;\n  color: var(--text-primary);\n  max-height: calc(100vh - 150px);\n  overflow: auto;\n}\n\n.process-loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  background-color: var(--secondary-background);\n  width: 175px;\n  height: 175px;\n  border-radius: 10px;\n  position: absolute;\n  margin-left: auto;\n  margin-right: auto;\n  left: 0;\n  right: 0;\n  text-align: center;\n  top: 30%;\n}\n\n.process-loader p {\n  color: var(--theme-consistent);\n}\n\n.success-message {\n  background-color: #dff0d8;\n  color: #3c763d;\n  padding: 15px;\n  border-radius: 4px;\n  margin-top: 20px;\n}\n\n.success-message p {\n  margin: 0;\n}\n\n.cancel-information {\n  color: var(--text-primary);\n}\n\n.cancelEndDate {\n  font-weight: bolder;\n}\n\n.error-message {\n  color: red;\n  font-weight: bold;\n  background-color: #f0d8d8;\n  padding: 15px;\n  border-radius: 4px;\n  margin-top: 20px;\n}\n\n.subInfo {\n  color: var(--text-primary);\n}\n\n.subscription-details {\n  color: var(--text-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
