import React, { useState, useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

function useHeartbeat() {
  const HEARTBEAT_INTERVAL = 60 * 1000; // send heartbeat every 60 seconds
  const DEBOUNCE_TIMEOUT = 5 * 1000; // debounce timeout of 5 seconds
  const INACTIVITY_TIMEOUT = 120 * 1000; // disconnect after 120 seconds of inactivity

  const [cable, setCable] = useState(null);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    let cleanup;

    if (loggedIn && user && token) {

      const authToken = token;
      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${authToken}`);

      setCable(cable);

      let subscription;
      let lastActive = Date.now();
      let inactivityCheckInterval;
      let heartbeatTimeout;

      function connect() {
        if (cable && !cable.connection.isActive()) {
          cable.connect();
        }

        if (!subscription) {
          subscription = cable.subscriptions.create(
            { channel: 'UserStatusChannel', auth_token: authToken, user_id: user.id },
            {
              received: (data) => {
                setCable(cable);
                updateLastActive();
              },
              connected: () => {
                // console.log('Connected to UserStatusChannel');
                updateLastActive(); // Update last active timestamp on connection
                scheduleHeartbeat();
                inactivityCheckInterval = setInterval(checkInactivity, INACTIVITY_TIMEOUT / 2);
              },
              disconnected: () => {
                // console.log('Disconnected from UserStatusChannel');
                clearInterval(inactivityCheckInterval);
                clearTimeout(heartbeatTimeout);
                subscription = null;
              },
            }
          );
        }
      }

      connect();

      function updateLastActive() {
        lastActive = Date.now();
      }

      function checkInactivity() {
        if (Date.now() - lastActive >= INACTIVITY_TIMEOUT) {
          clearInterval(inactivityCheckInterval);
          if (subscription) {
            subscription.unsubscribe();
            subscription = null;
          }
          cable.disconnect();
        }
      }

      function sendHeartbeat() {
        if (subscription) {
          subscription.send({ type: "heartbeat" });
        }
      }

      function scheduleHeartbeat() {
        heartbeatTimeout = setTimeout(() => {
          sendHeartbeat();
          scheduleHeartbeat();
        }, HEARTBEAT_INTERVAL);
      }

      const debouncedUpdateInactivityTimeout = debounce(() => {
        updateLastActive();
        if (!cable.connection.isActive()) {
          connect(); // Reconnect if the connection is not active
        }
        clearInterval(inactivityCheckInterval);
        inactivityCheckInterval = setInterval(checkInactivity, INACTIVITY_TIMEOUT / 2);
      }, DEBOUNCE_TIMEOUT);


      document.addEventListener('touchstart', debouncedUpdateInactivityTimeout);
      document.addEventListener('click', debouncedUpdateInactivityTimeout);
      document.addEventListener('keydown', debouncedUpdateInactivityTimeout);
      document.addEventListener('mousemove', debouncedUpdateInactivityTimeout);

      cleanup = () => {
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
        if (cable) {
          cable.disconnect();
        }

        document.removeEventListener('touchstart', debouncedUpdateInactivityTimeout);
        document.removeEventListener('click', debouncedUpdateInactivityTimeout);
        document.removeEventListener('keydown', debouncedUpdateInactivityTimeout);
        document.removeEventListener('mousemove', debouncedUpdateInactivityTimeout);

        clearInterval(inactivityCheckInterval);
        clearTimeout(heartbeatTimeout);
      };
    }

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [loggedIn, user, token]);

  return null;
}

export default useHeartbeat;
