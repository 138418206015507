// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minimalSub-btn {
  background: linear-gradient(135deg, #ff5e5e, #ff9966);
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  box-shadow: rgba(40, 40, 40, 0.35) 0px 5px 15px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;
  width: 85vw;
  margin-top: 16px;
  margin-bottom: 16px;

}

.stripe-minimal {
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/assets/StripeMinimal.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,+CAA+C;EAC/C,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;;AAErB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".minimalSub-btn {\n  background: linear-gradient(135deg, #ff5e5e, #ff9966);\n  color: white;\n  border-radius: 30px;\n  padding: 10px 20px;\n  box-shadow: rgba(40, 40, 40, 0.35) 0px 5px 15px;\n  border: none;\n  cursor: pointer;\n  font-size: 1.5em;\n  font-weight: bold;\n  transition: all 0.3s ease;\n  text-align: center;\n  width: 85vw;\n  margin-top: 16px;\n  margin-bottom: 16px;\n\n}\n\n.stripe-minimal {\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
