import axios from 'axios';
import Cookies from 'js-cookie';
import { setUser, clearUser } from '../redux/slices/userSlice';
import { setToken, clearToken } from '../redux/slices/tokenSlice';
import { clearAuthState } from '../redux/slices/authSlice';

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export async function getJWT(dispatch, setLoggedIn, cable) {
  if (!sessionStorage.refresh_token) {
    try {
      const refreshToken = sessionStorage.getItem("refresh_token");
      const fingerprint = Cookies.get('fingerprint');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/refresh_token`,
        { refresh_token: refreshToken },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Fingerprint": fingerprint
          },
        }
      );
      dispatch(setToken(response.data.jwt));
      return response.data.jwt;
    } catch (error) {
      console.error(error);
      logOut(dispatch, setLoggedIn, cable);
    }
  }
  return null;
}

export function logOut(dispatch, navigate) {
  // console.log('Logging out');
  dispatch(clearUser());
  dispatch(clearToken());
  dispatch(clearAuthState());
  sessionStorage.removeItem("userData");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("refresh_token");
  Cookies.remove('fingerprint');
  if (navigate) {
    navigate('/');
  }
}
