import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

import PasswordStrengthBar from '../PasswordStrengthBar';

import '../../assets/ResetPassword.css';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useParams();
  const navigate = useNavigate();

  const resetPassword = (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setErrorMessage('Passwords do not match');
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/users/update_password/${token}`, {
        password,
        password_confirmation: passwordConfirmation,
      })
      .then((response) => {
        if (response.data.status === 'success') {
          setSuccessMessage(response.data.message); // Display the success message returned by the API
          setErrorMessage('');
          navigate('/login');

        } else {
          setErrorMessage(response.data.message); // Display the error message returned by the API
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={resetPassword}>
        <h2>Reset Password</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New Password"
        />
        <input
          type="password"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          placeholder="Confirm Password"
        />
        <PasswordStrengthBar password={password} />
        <button type="submit">Reset Password</button>
      </form>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default ResetPassword;
