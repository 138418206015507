import { createSlice } from '@reduxjs/toolkit';

const deckSlice = createSlice({
  name: 'deck',
  initialState: {
    isFetching: false,
    isFilterLoading: false,
    data: [],
    currentIndex: -1,
    expandSearch: false,
  },
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsFilterLoading: (state, action) => {
      state.isFilterLoading = action.payload;
    },
    setExpandSearch: (state, action) => {
      state.expandSearch = action.payload;
    },
    // setData: (state, action) => {
    //   state.data = action.payload;
    //   state.currentIndex = action.payload.length - 1; // Reset currentIndex when setting new data
    // },
    setData: (state, action) => {
      state.data = action.payload;
      state.currentIndex = action.payload.length - 1;
    },
    incrementCurrentIndex: (state) => {
      state.currentIndex += 1;
    },
    decrementCurrentIndex: (state) => {
      state.currentIndex -= 1;
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload
    }
    // Add other reducer functions here
  },
});

export const {
  setIsFetching,
  setIsFilterLoading,
  setData,
  setCurrentIndex,
  incrementCurrentIndex,
  decrementCurrentIndex,
  setExpandSearch,
} = deckSlice.actions;

export default deckSlice.reducer;
