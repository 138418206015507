import React, { useState } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPlus } from '@fortawesome/free-solid-svg-icons';

import '../../assets/ImageUpload.css'

function ImageUpload({ user, token }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleImageChange = async (event) => {
    setSelectedFile(event.target.files[0]);

    if (!event.target.files[0]) return;

    setUploading(true);

    const formData = new FormData();
    formData.append('user[profile_image]', event.target.files[0]);

    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/users/${user.id}`, formData, config)
      .then((response) => {
        if (response.status === 200) {
          setUploadMessage('Profile image uploaded successfully');
          setTimeout(() => {
            setUploadMessage('');
          }, 2000);
        } else {
          setUploadMessage('Error uploading profile image');
          setTimeout(() => {
            setUploadMessage('');
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setUploadMessage('Error uploading profile image');
        setTimeout(() => {
            setUploadMessage('');
        }, 2000);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const imageUrl = user.profile_image_url ? user.profile_image_url : require('../../assets/images/dummy-pic.jpg');

  return (
    <div className="profile-image-container">
      <div
        className="profile-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <label htmlFor="file-upload" className="profile-image-label" aria-label="Upload Image">
        <FontAwesomeIcon icon={faCamera} className="camera-icon"/>
        <FontAwesomeIcon icon={faPlus} className="plus-icon"/>
        <input
          id="file-upload"
          type="file"
          onChange={handleImageChange}
          className="file-upload-input"
          style={{ display: "none" }}
        />
      </label>
      {uploadMessage && <p className='image-upload-message'>{uploadMessage}</p>}
    </div>
  );
}

export default ImageUpload;
