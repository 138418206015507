import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import '../assets/RatingSlider.css';
import imdbLogo from '../assets/images/imdb_logo.png';
import rtLogo from '../assets/images/Rotten_tomato_logo.png';
import metacriticLogo from '../assets/images/metacritic-logo-original.png';
import plexLogo from '../assets/images/plex-logo.png'

function RatingSlider({ rating, onRatingChange }) {
  const [currentRating, setCurrentRating] = useState(rating.ratingValue);

  const handleSliderChange = (value) => {
    setCurrentRating(value);
  };

  const handleSliderRelease = () => {
    onRatingChange(rating.source, currentRating);
  };

  const logoMap = {
    'Internet Movie Database': imdbLogo,
    'Rotten Tomatoes': rtLogo,
    'Metacritic': metacriticLogo,
    'Plex': plexLogo,
  };

  const getSliderProps = () => {
    switch(rating.source) {
      case 'Internet Movie Database':
        return { min: 0, max: 10, step: 0.1, format: (value) => value.toFixed(1) };
      case 'Rotten Tomatoes':
        return { min: 0, max: 100, step: 1, format: (value) => `${value}%` };
      case 'Metacritic':
        return { min: 0, max: 100, step: 1, format: (value) => value };
      case 'Plex':
        return { min: 0, max: 10, step: 0.1, format: (value) => value.toFixed(1) };
      default:
        return { min: 0, max: 10, step: 0.1, format: (value) => value.toFixed(1) };
    }
  };

  const sliderProps = getSliderProps();

  return (
    <div className="rating-slider-content">
      <div className="source-logo-container">
        <img src={logoMap[rating.source]} alt={`${rating.source} logo`} />
        <label>{rating.source}</label>
      </div>
      <div className="slider-container">
        <ReactSlider
          {...sliderProps}
          className="horizontal-slider"
          thumbClassName="rating-thumb"
          trackClassName="rating-track"
          value={currentRating}
          onChange={handleSliderChange}
          onAfterChange={handleSliderRelease}
        />
        <span>{sliderProps.format(currentRating)}</span>
      </div>
    </div>
  );
}

export default RatingSlider;
