import axios from "axios";
import React, { useState, useEffect } from "react";
import useDebounce from "./useDebounce";
import SearchUsers from "./SearchUsers";
import { useSelector } from 'react-redux';

import "../assets/userList.css";

const UserList = () => {

  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const [sentInvitationsList, setSentInvitationsList] = useState([]);
  const [sentRequest, setSentRequest] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [lastSearchValue, setLastSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [recentSearches, setRecentSearches] = useState(() => {
    const savedSearches = localStorage.getItem("recentSearches");
    return savedSearches ? JSON.parse(savedSearches) : [];
  });

  const imageUrl = user && user.profile_image_url ? user.profile_image_url : require('../assets/images/dummy-pic.jpg');

  const handleRecentSearchClick = (search) => {
    setSearchValue(search.value);
  };

  useEffect(() => {
    if (user && token) {
      const fetchUserRequests = async () => {
        let config = {
          headers: {
            Authorization: "Bearer " + token,
            responseType: "json",
          },
        };
        // this api request gets all invites I've sent
        axios
          .get(`${process.env.REACT_APP_API_URL}/pending/${user.id}`, config)
          .then((response) => {
            setSentInvitationsList(response.data.pending_users);
            setAllRequestsToPending(response.data.pending_users);
          });
      };
      fetchUserRequests();
    }
  }, [user, token]);

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const handleSearch = (e) => {
    if (token && user) {
      setSearchValue(e.target.value);
    }
  };

  const searchUsers = async (page, reset = false) => {
    if (searchValue === "") {
      setHasMore(false);
      setSearchResults([]);
      return;
    }

    setLoading(true);
    if (reset) {
      setPage(1);
      setHasMore(true);
      setLastSearchValue(searchValue);
    }

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        responseType: "json",
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/search?search=${searchValue}&page=${page}`, config)
      .then((response) => {
        if (response.data.users.length === 0) {
          setHasMore(false);
        } else {
          if (reset) {
            setSearchResults(response.data.users);
          } else {
            setSearchResults((prevSearchResults) => [...prevSearchResults, ...response.data.users]);
          }
          setPage(page + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loggedIn && user && token) {
      if (debouncedSearchValue !== "") {
        searchUsers(debouncedSearchValue !== lastSearchValue ? 1 : page, debouncedSearchValue !== lastSearchValue);

        if (debouncedSearchValue !== lastSearchValue && debouncedSearchValue.length >= 3) {
          setRecentSearches((prevSearches) => {
            const newSearches = [
              { value: debouncedSearchValue },
              ...prevSearches.slice(0, 3),
            ];
            localStorage.setItem("recentSearches", JSON.stringify(newSearches));
            return newSearches;
          });
        }
      }
    }
  }, [debouncedSearchValue]);


  const setAllRequestsToPending = (pendingUsers) => {
    const updatedSentRequests = {};
    pendingUsers.forEach((invitation) => {
      updatedSentRequests[invitation.user.id] = true;
    });
    setSentRequest(updatedSentRequests);
  };

    const sendFriendRequest = async (friend) => {
      const user1 = user.id;
      const user2 = friend.id;
      const url = `${process.env.REACT_APP_API_URL}/invitations`;

      axios
        .post(
          url,
          {
            user_id: user1,
            friend_id: user2,
          },
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === "invite_sent") {
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            console.log("Invite already pending");
          } else {
            console.log("Error sending friend request:", error);
          }
        });
    };

    const handleClick = (user) => {
      sendFriendRequest(user);
      setSentRequest({...sentRequest, [user.id]: true});
    }

    const fetchMoreUsers = () => {
      if (!loading && searchValue === lastSearchValue) {
        searchUsers(page);
      }
    };

  return (
    user ? (
      <SearchUsers
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      displayResults={searchResults}
      fetchMoreUsers={fetchMoreUsers}
      hasMore={hasMore}
      loading={loading}
      sendFriendRequest={sendFriendRequest}
      sentInvitationsList={sentInvitationsList}
      sentRequest={sentRequest}
      setSentRequest={setSentRequest}
      recentSearches={recentSearches}
      handleRecentSearchClick={handleRecentSearchClick}
      imageUrl={imageUrl}
    />
    ) : null
  );
};

export default UserList;
