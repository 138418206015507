import React from "react";
import onlineIcon from '../assets/images/online-icon.png';

import '../assets/NotificationCards.css';

const NotificationCard = ({ firstname, lastname, online, imageUrl }) => {
  return (
    <div className="notificationCard">
      <img className="notificationImage" src={imageUrl} alt="profile-pic" />
      <div className="notificationBody">
        <h2>{firstname} {online && <img className="onlineIcon" src={onlineIcon} alt="online" data-testid="online-status" />}</h2>
        <p>{lastname}</p>
      </div>
    </div>
  );
};

export default NotificationCard;
