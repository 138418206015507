import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../slices/userSlice';
import deckReducer from '../slices/deckSlice';
import tokenReducer from '../slices/tokenSlice';
import authReducer from '../slices/authSlice';
import subscriptionReducer from '../slices/subscriptionSlice';
import filterReducer from '../slices/filterSlice';

const rootReducer = combineReducers({
  user: userReducer,
  deck: deckReducer,
  token: tokenReducer,
  auth: authReducer,
  subscription: subscriptionReducer,
  filter: filterReducer,
  // Add other reducers here
});

export default rootReducer;
