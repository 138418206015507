import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/userSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';

import '../../assets/TwoFactorAuth.css'

export default function TwoFactorAuth({ verificationSuccessful, user_id, onVerification }) {
  const [totpCode, setTotpCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const dispatch = useDispatch();

  function handleVerifyTotp(event) {
    event.preventDefault();
    event.target.reset();


    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login_verify_totp`, { user_id, totp_code: totpCode }, { withCredentials: true })
      .then((response) => {
        dispatch(setUser(response.data.user));
        dispatch(setToken(response.data.jwt));
        dispatch(setLoggedIn(true));
        dispatch(setSubscriptionStatus(response.data.user.subscription_status));
        dispatch(setSubscriptionEndDate(response.data.user.subscription_end_date));
        dispatch(setCancelAtPeriodEnd(response.data.user.cancel_at_period_end));
        sessionStorage.setItem('userData', JSON.stringify(response.data.user));
        sessionStorage.setItem('token', response.data.jwt);
        sessionStorage.setItem('refresh_token', response.data.refresh_token);
        onVerification(true);
      })
      .catch((error) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data.message;
          setInvalidCode(errorMessage);
        } else {
          errorMessage = "Unknown error";
          console.log(error);
        }
        onVerification(false, errorMessage);
      });
  }

  return (
    <div className="auth-verification-container">
      <form className="twofactorauth-form" onSubmit={handleVerifyTotp}>
        <h2>Verify</h2>
        <p>(Enter a one time password from your authenticator)</p>
        <input
          type="text"
          name="totpCode"
          placeholder=""
          onChange={(e) => setTotpCode(e.target.value)}
        />
        <button data-testid="2fa-login-btn" onClick={() => (setInvalidCode(false))} type="submit">Verify</button>
        { invalidCode &&
          <h4 className="twofactorauth-error">{invalidCode}</h4>
        }
        {verificationSuccessful && <h4 className="twofactorauth-success">Verification successful!</h4>}
      </form>
    </div>
  );
}
