import React from "react";
import { animated, to } from "react-spring";

import "../assets/Card.css";

const HomeCard = ({
  ShowDetail,
  DetailRequest,
  ActivateModal,
  i,
  x,
  y,
  trans,
  bind,
  data,
}) => {
  const { poster_path, id } = data;

  const imageUrl = `https://image.tmdb.org/t/p/w500/${poster_path}`;

  return poster_path ? (
    <animated.div
      className="animated"
      key={i}
      {...bind(i)}
      style={{
        transform: to(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`
        ),
      }}
    >
      <div className="card" data-testid={`card-${i}`}>
        <img key={id} src={imageUrl} alt="poster_picture" />
      </div>
    </animated.div>
  ) : null;
};

export default HomeCard;
