import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HomeDeck from './HomeDeck';
import { Link } from 'react-router-dom';
import logo from '../assets/images/flickswipe_logo.png';
import '../assets/Home.css';

const Home = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.user.userData);
  const [showModal, setShowModal] = useState(true);

  const handleTrySwiping = () => {
    setShowModal(false);
  };

  return (
    <div className="home-container">
      {!loggedIn && !user && showModal && (
        <div className="modal" role="dialog">
          <div className="LoggedOut-homepage-container">
            <div className="LoggedOut-logo-container">
              <img src={logo} alt="Flickswipe logo" className="LoggedOut-flickswipe-logo" />
            </div>
            <div className="LoggedOut-tagline-container">
              <h1 className="LoggedOut-tagline">Swipe, Match, Watch!</h1>
            </div>
            <button onClick={handleTrySwiping} className="try-swiping-btn">Try swiping!</button>
          </div>
        </div>
      )}
      {!showModal && (
        <>
          <div className="home-content">
            <HomeDeck />
            {!loggedIn && !user && (
              <Link className="home-signup-link" to="/signup">Sign up now</Link>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
