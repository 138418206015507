import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SignupVerifyTwoFactorAuth from './SignupVerifyTwoFactorAuth';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

import '../../assets/TwoFactorSetup.css'

function TwoFactorSetup({ user_id, jwt, setIsTwoFactorVerified }) {
  const [qrCodeSVG, setQrCodeSVG] = useState(null);
  const [totpSecret, setTotpSecret] = useState(null);
  const [showQrCode, setShowQrCode] = useState(true);
  const [show2FACodeInput, setShow2FACodeInput] = useState(false);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [copied, setCopied] = useState(false);


  useEffect(() => {
    fetchQrCode();
  }, []);

  function fetchQrCode() {
    axios.post(`${process.env.REACT_APP_API_URL}/users/${user_id}/enable_totp`, {})
      .then((response) => {
        if (response.data.qrcode) {
          setQrCodeSVG(response.data.qrcode);
          setTotpSecret(response.data.totp_secret);
          setShow2FACodeInput(true);
        } else {
          alert("1 Error enabling 2FA. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("2 Error enabling 2FA. Please try again.");
      });
  };


  function handle2FAVerification(success) {
    if (success) {
      setVerificationSuccessful(true);
      setShowQrCode(false);
      setShow2FACodeInput(false);
      setIsTwoFactorVerified(true);
    } else {
      alert('Verification failed. Please try again.');
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(totpSecret.secret).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };


  return (
    <div className="twofactor-setup-container">
      {qrCodeSVG && (
        <div
          className="qr-code-container"
          dangerouslySetInnerHTML={{ __html: qrCodeSVG }}
        >
        </div>
      )}
      {totpSecret && (
        <div className="totp-secret-container">
          <p>Copy and paste the code below into your authenticator app if you cannot scan the QR code.</p>
          <div
            className={`totpSecret secret-box${copied ? " copied" : ""}`}
            onClick={copyToClipboard}
          >
            <span className="secret-content">{!copied ? totpSecret.secret : 'Copied'}</span>
            <FontAwesomeIcon icon={faClipboard} style={{ marginLeft: '8px' }} />
          </div>
        </div>
      )}
      {show2FACodeInput && (
        <SignupVerifyTwoFactorAuth user_id={user_id} onVerification={handle2FAVerification} />
      )}
      {verificationSuccessful && <h4 className="twofactorauth-success">Verification successful!</h4>}
    </div>
  );
}

export default TwoFactorSetup;
