import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';

const RevertChanges = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const revert_token = queryParams.get('revert_token');
  const reset_password_token = queryParams.get('reset_password_token');

  useEffect(() => {
    const revertChanges = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/revert_changes`, {
          reset_password_token: reset_password_token
        })
        if (response.data.status === 'success') {
          navigate(`/password_resets/${reset_password_token}`);
        } else {
          // Handle the error, e.g., show an error message
          console.error(response.data.error);
        }
      } catch (error) {
        // Handle the error, e.g., show an error message
        console.error(error);
      }
    };

    revertChanges();
  }, [revert_token, reset_password_token, navigate]);

  return (
    <div>
      <h1>Resetting Password...</h1>
      {/* Optionally, you can add a spinner or progress indicator here */}
    </div>
  );
};

export default RevertChanges;
