// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-container {
  padding: 20px;
  line-height: 1.6;
  font-size: 16px;
  color: var(--text-primary);
  background-color: var(--background);
  max-width: 800px;
  margin: 0 auto;
  overflow: auto;
  height: calc(100vh - 130px);
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
  color: var(--text-primary);
}

.privacy-policy-container h1 {
  margin-bottom: 20px;
}

.privacy-policy-container h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy-container a {
  color: var(--theme-consistent);
}
`, "",{"version":3,"sources":["webpack://./src/assets/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,mCAAmC;EACnC,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,2BAA2B;AAC7B;;AAEA;;EAEE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".privacy-policy-container {\n  padding: 20px;\n  line-height: 1.6;\n  font-size: 16px;\n  color: var(--text-primary);\n  background-color: var(--background);\n  max-width: 800px;\n  margin: 0 auto;\n  overflow: auto;\n  height: calc(100vh - 130px);\n}\n\n.privacy-policy-container h1,\n.privacy-policy-container h2 {\n  color: var(--text-primary);\n}\n\n.privacy-policy-container h1 {\n  margin-bottom: 20px;\n}\n\n.privacy-policy-container h2 {\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.privacy-policy-container a {\n  color: var(--theme-consistent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
