import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import useDebounce from './useDebounce';
import "../assets/userList.css";

const SearchFriends = ({ searchFriends, fetchInitialFriends }) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [isMounted, setIsMounted] = useState(false);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const handleSearch = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (loggedIn && user && isMounted) {
      if (debouncedInputValue) {
        searchFriends(debouncedInputValue);
      } else {
        fetchInitialFriends(true);
      }
    }
  }, [loggedIn, user, debouncedInputValue, isMounted]);

  return (
    <>
      <div className="userList-header">
        <h1>Search Friends</h1>
        <input type="text" onChange={handleSearch} value={inputValue} />
      </div>
    </>
  );
};

export default SearchFriends;
