import React from 'react';
import '../assets/SwitchTheme.css';

const SwitchTheme = ({ setTheme, storedTheme, setStoredTheme }) => {
  const switchTheme = () => {
    const newTheme = storedTheme === 'light' ? 'dark' : 'light';
    setTheme(newTheme)
    setStoredTheme(newTheme);
  };

  return (
    <div className="switch-theme">
      <p>{storedTheme.charAt(0).toUpperCase() + storedTheme.slice(1)}</p>
      <label htmlFor="themeSwitch" className="switch">
        <input
          type="checkbox"
          id="themeSwitch"
          checked={storedTheme === 'dark'}
          onChange={switchTheme}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default SwitchTheme;
