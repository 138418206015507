import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/Tutorial.css';

import Swiping from '../assets/videos/Swiping.mp4';
import SwipingThumbnail from '../assets/images/Swiping.avif';
import Undo from '../assets/videos/UndoAndMore.mp4';
import UndoThumbnail from '../assets/images/Explore.avif';
import Match from '../assets/videos/Match.mp4';
import MatchThumbnail from '../assets/images/Match.avif';
import MatchShow from '../assets/videos/MatchShow.mp4';
import MatchShowThumbnail from '../assets/images/MatchShow.avif';


const Tutorial = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const tutorialSteps = [
    {
      step: 1,
      content: 'Tap "Swipe" to start exploring content. Swipe right to like or left to dislike content. For additional options and info, tap the hamburger icon.',
      video: Swiping,
      thumbnail: SwipingThumbnail,
    },
    {
      step: 2,
      content: 'Step 2: Undo a swipe with the top-left icon. For personalised content, after swiping the initial cards, pick "Similar to what you like" or "Different from what you like". Non-subscribers can select twice daily.',
      video: Undo,
      thumbnail: UndoThumbnail,
    },
    {
      step: 3,
      content: 'Keep the swipes coming! If you and a friend both like the same content while online, you’ll match!',
      video: Match,
      thumbnail: MatchThumbnail,
    },
    {
      step: 4,
      content: 'Hit "Watch now" for viewing options, or relax - it’s saved in "Matches" for later. Missed a live match? No worries, we create daily matches even if you and your friends aren’t swiping simultaneously.',
      video: MatchShow,
      thumbnail: MatchShowThumbnail,
    },
  ];


  const handleNext = () => setCurrentStep(prevStep => Math.min(prevStep + 1, tutorialSteps.length - 1));
  const handlePrev = () => setCurrentStep(prevStep => Math.max(prevStep - 1, 0));
  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <div className={`tutorial-container ${isExpanded ? 'expanded' : ''}`}>
      <div className="tutorial-toggle" onClick={handleToggle}>
        <div className={`tutorial-arrow ${isExpanded ? 'hide-arrow' : ''}`}>&#x2191;</div>
        <div className={`tutorial-text ${isExpanded ? 'hide-text' : ''}`}>Tutorial</div>
      </div>
      {isExpanded && (
        <div className="tutorial-expanded">
          <div className="tutorial-content">
            <div className="tutorial-step">
            <video
              className="tutorial-video"
              src={tutorialSteps[currentStep].video}
              poster={tutorialSteps[currentStep].thumbnail}
              controls
              muted
              alt={`Step ${tutorialSteps[currentStep].step}`}
            ></video>
              <p>{tutorialSteps[currentStep].content}</p>
            </div>
            <button onClick={handlePrev} disabled={currentStep === 0}>Previous</button>
            {currentStep === tutorialSteps.length - 1 ? (
              <Link to="/deck">
                <button>Start Swiping!</button>
              </Link>
            ) : (
              <button onClick={handleNext}>Next</button>
            )}
          </div>
          <div className="bottom-container" onClick={handleToggle}>
            <div className="tutorial-arrow">&#x2193;</div>
            <div className="tutorial-text">Hide</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tutorial;
