import React from "react";
import { animated, to } from "react-spring";
import "../../assets/Card.css";
import HeartButton from '../Card Components/HeartButton';
import placeholderImage from '../../assets/images/flickswipe_logo.png';
import { useSelector } from "react-redux";

const Card = ({
  i,
  x,
  y,
  bind,
  handleDragEnd,
}) => {
  const user = useSelector((state) => state.user.userData) || {};
  const data = useSelector((state) => state.deck.data) || [];
  const token = useSelector((state) => state.token.value);
  const item = data[data.length - 1 - i] || {};

  let imageURL = placeholderImage;

  if (item.movie) {
    imageURL = item.movie.poster_path || placeholderImage;
  } else if (item.tv_show) {
    imageURL = item.tv_show.poster_path || placeholderImage;
  } else if (item.thumb && user?.plex_address && user?.plex_access_token) {
    imageURL = `https://${user.plex_address}${item.thumb}?X-Plex-Token=${user.plex_access_token}` ||
               `https://${user.plex_address}:${user.plex_port || ''}${item.thumb}?X-Plex-Token=${user.plex_access_token}`;
  }

  return (
    <animated.div
      className="animated"
      key={i}
      {...bind(i, item.id, item.ratingKey)}
      onDragEnd={handleDragEnd}
      style={{
        transform: to(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`
        ),
      }}
    >
      <div className="card" data-testid={`card-${item.id || 'placeholder'}`} >
        <img src={imageURL} alt="poster_picture" onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage }} />
        {token && (item.id || item.ratingKey) && (
          <HeartButton
            token={token}
            contentId={item.id || item.ratingKey}
            user={user}
            source={item.ratingKey ? 'plex' : 'db'}
          />
        )}
      </div>
    </animated.div>
  );
};

export default Card;
