import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import { setIsFetching, setIsFilterLoading, setData, setCurrentIndex } from '../redux/slices/deckSlice';
import { useSelector, useDispatch } from 'react-redux';

const useDeckFetch = (token, user, selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [expandSearch, setExpandSearch] = useState(false);
  const [lastPreference, setLastPreference] = useState("");
  const isFetching = useSelector((state) => state.deck.isFetching);

  const fetchDeck = useCallback(async () => {
    if (isFetching) return;

    const selectedGenreIds = Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);

    dispatch(setIsFetching(true));
    dispatch(setIsFilterLoading(true));

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const serverDataResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}${expandSearch ? "&expand_search=true" : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating =>` &rating[]=${rating.source}&rating_value[]=${rating.ratingValue}`).join('') : ""}&selected_libraries=${selectedPlexLibraries.join(",")}&selected_collections=${selectedCollections.join(",")}`,
        config
      );

      const { contents, metadata } = serverDataResponse.data;

      if (metadata.no_results) {
        dispatch(setData([]));
        // Handle no results case, e.g., show a message or set a flag
      } else {
        dispatch(setData(contents));
      }

      dispatch(setCurrentIndex(0));
      dispatch(setIsFetching(false));
      dispatch(setIsFilterLoading(false));
    } catch (err) {
      setError(err);
      dispatch(setIsFetching(false));
      dispatch(setIsFilterLoading(false));
    }
  }, [token, user, selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset, expandSearch, dispatch]);

  const debouncedFetchDeck = useCallback(debounce(fetchDeck, 500), [fetchDeck]);

  useEffect(() => {
    debouncedFetchDeck();
    return () => {
      debouncedFetchDeck.cancel();
    };
  }, [debouncedFetchDeck]);

  return { error, expandSearch, setExpandSearch, lastPreference, setLastPreference };
};

export default useDeckFetch;
