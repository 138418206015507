import React from 'react';
import '../assets/PasswordStrengthBar.css';

export const getPasswordStrength = (password) => {
  let score = 0;

  if (password.length >= 8) {
    score++;
  }

  if (/\d/.test(password)) {
    score++;
  }

  if (/[a-z]/.test(password)) {
    score++;
  }

  if (/[A-Z]/.test(password)) {
    score++;
  }

  if (/[^\w\d\s]/.test(password)) {
    score++;
  }

  return score;
};

export const getPasswordStrengthText = (score) => {
  switch (score) {
    case 1:
      return 'Very Weak';
    case 2:
      return 'Weak';
    case 3:
      return 'Medium';
    case 4:
      return 'Strong';
    case 5:
      return 'Very Strong';
    default:
      return '';
  }
};

const PasswordStrengthBar = ({ password }) => {
  const score = getPasswordStrength(password);
  const barWidth = (score * 100) / 5;
  const strengthText = getPasswordStrengthText(score);

  return (
    <div className="password-strength-container">
      <div className="password-strength-bar-container">
        <div
          className="password-strength-bar"
          style={{ width: `${barWidth}%`, backgroundColor: `hsl(${(score * 120) / 5}, 100%, 50%)` }}
        />
      </div>
      {strengthText && <p className="password-strength-text">{strengthText}</p>}
    </div>
  );
};

export default PasswordStrengthBar;
