import React, { CSSProperties, useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import '../assets/ContentModal.css';
import CheckboxList from "./CheckboxList";
import FilterDropdown from "./FilterDropdown";
import ReactSlider from 'react-slider';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedGenres, setSelectedProviders } from '../redux/slices/filterSlice';

import placeholderImage from '../assets/images/flickswipe_logo.png';

import ContentDetail from "./ContentDetail";

const Modal = ({ user, isOpen, loading, closeModal, likedContent, selectedFriend, thumbnailDataUrls }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value);
  const { selectedGenres, selectedProviders } = useSelector((state) => state.filter);

  const [loaded, setLoaded] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [sortedByPopularity, setSortedByPopularity] = useState(false);
  const [sortedByGenres, setSortedByGenres] = useState(false);
  const [filteredByMovie, setFilteredByMovie] = useState(false);
  const [filteredByTv, setFilteredByTv] = useState(false);
  const [originalLikedContent, setOriginalLikedContent] = useState(likedContent);
  const [sliderValue, setSliderValue] = useState(270);
  const [filterByProvider, setFilterByProvider] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [animation, setAnimation] = useState("slide-in");
  const [disableScroll, setDisableScroll] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [filteredByPlex, setFilteredByPlex] = useState(false);
  const [displayedContent, setDisplayedContent] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollableRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (scrollableRef.current) {
      const scrollTop = scrollableRef.current.scrollTop;
      if (scrollTop > lastScrollTop && scrollTop > 50) {
        // Scrolling down and not at the top
        setIsHeaderVisible(false);
      } else if (scrollTop < lastScrollTop || scrollTop <= 50) {
        // Scrolling up or near the top
        setIsHeaderVisible(true);
      }
      setLastScrollTop(scrollTop);
    }
  }, [lastScrollTop]);

  useEffect(() => {
    const scrollableDiv = scrollableRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const locationPreference = user.location_preference.toLowerCase();

  const itemsPerPage = 20;

  const genres = useMemo(() => {
    return [...new Set(originalLikedContent.flatMap((content) => content.genres || content.plex_item_details?.Genre?.tag || []))];
  }, [originalLikedContent]);

  const flatrate_providers = useMemo(() => {
    return originalLikedContent.reduce((acc, content) => {
      const providers = content.flatrate_providers || [];
      return providers.reduce((acc, provider) => {
        if (provider.logo_path && !acc.some((p) => p.logo_path === provider.logo_path)) {
          acc.push(provider);
        }
        return acc;
      }, acc);
    }, []);
  }, [originalLikedContent]);

  const filteredContent = useMemo(() => {
    return likedContent
      .map(content => {
        const streamingInfo = content.streaming_info?.[locationPreference]?.tmdb_providers || {};
        let posterPath = content.poster_path;

        if (content.plex_item_details && content.plex_item_details.thumb) {
          posterPath = thumbnailDataUrls[content.plex_item_details.thumb] || posterPath;
        }

        return {
          ...content,
          flatrate_providers: streamingInfo.flatrate || [],
          buy_providers: streamingInfo.buy || [],
          rent_providers: streamingInfo.rent || [],
          watch_links: streamingInfo.watch_links || {},
          contentRuntime: content.runtime || (content.plex_item_details?.duration ? content.plex_item_details.duration / 60000 : null),
          poster_path: posterPath
        };
      })
      .filter((content) => {
        const isMovie = content.media_type === "movie" || content.plex_item_details?.type === "movie";
        const isTv = content.media_type === "tv" || content.plex_item_details?.type === "show";
        const isPlex = content.plex_rating_key != null;
        const passesGenreFilter = !sortedByGenres || (content.genres && content.genres.some((genre) => selectedGenres.includes(genre))) ||
          (content.plex_item_details?.Genre?.tag && selectedGenres.includes(content.plex_item_details.Genre.tag));

        const passesProviderFilter = selectedProviders.length === 0 || (
          [...content.flatrate_providers, ...content.buy_providers, ...content.rent_providers].some(provider =>
            selectedProviders.includes(provider.provider_name.toLowerCase())
          )
        );

        const passesRuntimeFilter = content.contentRuntime === null || content.contentRuntime <= sliderValue;

        return ((!filteredByMovie && !filteredByTv && !filteredByPlex) ||
                (filteredByMovie && isMovie) ||
                (filteredByTv && isTv) ||
                (filteredByPlex && isPlex)) &&
               passesGenreFilter &&
               passesProviderFilter &&
               passesRuntimeFilter;
      })
      .sort((a, b) => {
        if (sortedByPopularity) {
          const aPopularity = a.popularity || a.plex_item_details?.audienceRating || 0;
          const bPopularity = b.popularity || b.plex_item_details?.audienceRating || 0;
          return bPopularity - aPopularity;
        } else {
          if (a.contentRuntime === null && b.contentRuntime === null) return 0;
          if (a.contentRuntime === null) return 1;
          if (b.contentRuntime === null) return -1;
          return a.contentRuntime - b.contentRuntime;
        }
      });
  }, [likedContent, locationPreference, filteredByMovie, filteredByTv, filteredByPlex, sortedByGenres, selectedProviders, selectedGenres, sliderValue, sortedByPopularity, thumbnailDataUrls]);

  const checkboxOptions = useMemo(() => {
    const baseOptions = ["Movies", "Shows", "Popular"];
    return user.plex_id ? [...baseOptions, "Plex"] : baseOptions;
  }, [user.plex_id]);

  const handleCheckboxChange = (selectedOptions) => {
    setSelectedCheckboxes(selectedOptions);
    setFilteredByMovie(selectedOptions.includes("Movies"));
    setFilteredByTv(selectedOptions.includes("Shows"));
    setSortedByPopularity(selectedOptions.includes("Popular"));
    setFilteredByPlex(selectedOptions.includes("Plex"));
  };

  const handleGenreChange = (genres) => {
    dispatch(setSelectedGenres(genres));
    setSortedByGenres(genres.length > 0);
  };

  const handleProviderChange = (providers) => {
    dispatch(setSelectedProviders(providers));
    setFilterByProvider(providers.length > 0);
  };

  const formatRuntime = (runtime) => {
    if (runtime < 60) {
      return `${runtime} minutes`;
    } else if (runtime === 60) {
      return `1 hour`;
    } else {
      const hours = Math.floor(runtime / 60);
      const minutes = runtime % 60;
      if (minutes === 0) {
        return `${hours} hours`;
      } else {
        return `${hours} hours ${minutes} minutes`;
      }
    }
  };

  const override: CSSProperties = {
    borderColor: "#ff7c62",
    height: "45px",
    width: "40px"
  };

  const handleChange = (value) => {
    setSliderValue(value);
  };

  const handleClick = (content) => {
    const thumbnailDataUrl = content.plex_item_details?.thumb
      ? thumbnailDataUrls[content.plex_item_details.thumb]
      : null;

    setSelectedContentId({
      ...content,
      thumbnail_data: thumbnailDataUrl || content.thumbnail_data,
    });
  };

  const closeDropdown = () => {
    setShowDropdown(false);
    setDisableScroll(false);
  };

  useEffect(() => {
    setDisplayedContent(filteredContent.slice(0, itemsPerPage));
    setHasMore(filteredContent.length > itemsPerPage);
  }, [filteredContent]);

  const fetchMoreData = useCallback(() => {
    const currentLength = displayedContent.length;
    const nextBatch = filteredContent.slice(currentLength, currentLength + itemsPerPage);

    if (nextBatch.length > 0) {
      setDisplayedContent(prevContent => [...prevContent, ...nextBatch]);
      setHasMore(currentLength + nextBatch.length < filteredContent.length);
    } else {
      setHasMore(false);
    }
  }, [displayedContent, filteredContent]);

  return (
    <>
      {selectedContentId && (
        <ContentDetail
          content={selectedContentId}
          closeDetail={() => setSelectedContentId(null)}
          token={token}
          user={user}
        />
      )}
      <div className={`modal-overlay ${selectedContentId ? 'content-detail-visible' : ''}`} />
      <div
        className={`modal-content ${animation} ${disableScroll ? 'no-scroll' : ''}`}
        id="scrollableDiv"
        ref={scrollableRef}
      >
        {loading ? (
          <div className="loading-container">
            <ClipLoader
              size={35}
              aria-label="Loading Spinner"
              data-testid="loader"
              cssOverride={override}
            />
            <p className="loading-text">Loading Matches...</p>
          </div>
        ) : (
          <>
            <div className={`modal-header ${isHeaderVisible ? 'visible' : 'hidden'}`}>
              <h2 className="modal-title">Flickswipe matches with {selectedFriend.friend.firstname}</h2>
              <button className="modal-close-btn" onClick={() => closeModal(false)} aria-label="Close">
                <FontAwesomeIcon icon={faSquareXmark} size="2x" />
              </button>
            </div>
            <div className="modal-filters">
              <div className="filter-section">
                <CheckboxList
                  options={checkboxOptions}
                  selectedOptions={selectedCheckboxes}
                  onChange={handleCheckboxChange}
                  testId="checkboxList"
                />
              </div>
              <div className="filter-section">
                <ReactSlider
                  className="content-react-slider"
                  thumbClassName="content-thumb"
                  trackClassName="content-track"
                  min={0}
                  max={200}
                  step={10}
                  value={sliderValue}
                  onChange={handleChange}
                />
                <p className="slider-label">Runtime: {formatRuntime(sliderValue)}</p>
              </div>
              <button
                className="filter-more-btn"
                onClick={() => {
                  setShowDropdown(true);
                  setDisableScroll(true);
                }}>
                Filter More
              </button>
            </div>
            {showDropdown && (
              <FilterDropdown
                showDropdown={showDropdown}
                closeDropdown={closeDropdown}
                originalLikedContent={likedContent}
                handleCheckboxChange={handleCheckboxChange}
                selectedCheckboxes={selectedCheckboxes}
                onGenresChange={handleGenreChange}
                onProvidersChange={handleProviderChange}
                selectedGenres={selectedGenres}
                selectedProviders={selectedProviders}
                onClose={() => setShowDropdown(false)}
                user={user}
              />
            )}
            <InfiniteScroll
              dataLength={displayedContent.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              <div className="content-grid">
                {displayedContent.map(content => (
                  <div className="content-card" key={`content-${content.content_id || content.plex_item_details?.ratingKey}`} onClick={() => handleClick(content)} data-testid="content-card">
                    <div className="thumbnail-container">
                      {!loaded[content.content_id || content.plex_item_details?.ratingKey] && (
                        <>
                          <div className="thumbnail-placeholder">
                            <img src={placeholderImage} alt="Placeholder" />
                          </div>
                          <div className="thumbnail-loading">
                            <ClipLoader
                              loading={!loaded[content.content_id || content.plex_item_details?.ratingKey]}
                              size={25}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              cssOverride={override}
                            />
                          </div>
                        </>
                      )}
                      <img
                        className={`content-img ${loaded[content.content_id || content.plex_item_details?.ratingKey] ? 'loaded' : ''}`}
                        src={content.poster_path || placeholderImage}
                        alt={content.title || content.name || content.plex_item_details?.title || 'Content'}
                        onLoad={() => setLoaded((prevLoaded) => ({ ...prevLoaded, [content.content_id || content.plex_item_details?.ratingKey]: true }))}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = placeholderImage;
                          setLoaded((prevLoaded) => ({ ...prevLoaded, [content.content_id || content.plex_item_details?.ratingKey]: true }));
                        }}
                      />
                    </div>
                    <div className="content-title">
                      {content.title || content.name || content.plex_item_details?.title}
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </>
        )}
      </div>
    </>
  );
};

export default Modal;
