// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-fb-button {
  font-family: sans-serif;
  font-weight: 900;
  margin: 12px 0 12px 0;
  display: inline-flex;
  align-items: center;
  background-color: #2374F2;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.custom-fb-button span {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/FacebookLogin.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,qBAAqB;EACrB,oBAAoB;EACpB,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".custom-fb-button {\n  font-family: sans-serif;\n  font-weight: 900;\n  margin: 12px 0 12px 0;\n  display: inline-flex;\n  align-items: center;\n  background-color: #2374F2;\n  color: #FFFFFF;\n  border: none;\n  border-radius: 4px;\n  padding: 10px 15px;\n  cursor: pointer;\n  font-size: 14px;\n  text-align: center;\n}\n\n.custom-fb-button span {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
