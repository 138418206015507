// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  position: relative;
  width: 70vw;
  height: 60vh;
  background-position: center;
  box-shadow: rgb(40 40 40 / 10%) 0px 0px 10px;
}

.card img {
  height: 58vh;
  border-radius: 10px;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/assets/Card.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,4CAA4C;AAC9C;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".card {\n  position: relative;\n  width: 70vw;\n  height: 60vh;\n  background-position: center;\n  box-shadow: rgb(40 40 40 / 10%) 0px 0px 10px;\n}\n\n.card img {\n  height: 58vh;\n  border-radius: 10px;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
