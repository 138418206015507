import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

import PasswordStrengthBar from '../PasswordStrengthBar';

import '../../assets/ResetPassword.css';

function ResetPasswordAndEmail() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const { token } = useParams();
  const navigate = useNavigate();

  const resetPassword = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/update_password_and_email/${token}`, {
        email: newEmail,
        password,
        password_confirmation: passwordConfirmation,
      });

      if (response.data.status === 'success') {
        navigate('/login');
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response.data.message);
    }
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={resetPassword}>
        <input
          data-testid="email-input"
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="New Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New Password"
        />
        <input
          type="password"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          placeholder="Confirm Password"
        />
        <PasswordStrengthBar password={password} />
        <button type="submit">Reset Password and Email</button>
      </form>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default ResetPasswordAndEmail;
