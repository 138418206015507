// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twofactor-setup-container {
  display: grid;
  align-items: center;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 90px;
  margin-bottom: 130px;
}

.signup-verify-two-factor-auth {
  max-width: 400px;
  width: 100%;
}

.twofactorauth-success {
  margin-top: 24px;
  color: green;
  font-weight: bold;
}

.totpSecret {
  font-weight: bolder;
  font-size: 12px;
}

.totp-secret-container {
  text-align: center;
}

.secret-box {
  display: inline-flex;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  min-width: 100px;
  justify-content: center;
  align-items: center;
}

.secret-box.copied {
  background-color: #28a745;
  color: #fff;
}

.secret-content {
  font-weight: bolder;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/TwoFactorSetup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".twofactor-setup-container {\n  display: grid;\n  align-items: center;\n}\n\n.qr-code-container {\n  display: flex;\n  justify-content: center;\n  position: relative;\n  top: 90px;\n  margin-bottom: 130px;\n}\n\n.signup-verify-two-factor-auth {\n  max-width: 400px;\n  width: 100%;\n}\n\n.twofactorauth-success {\n  margin-top: 24px;\n  color: green;\n  font-weight: bold;\n}\n\n.totpSecret {\n  font-weight: bolder;\n  font-size: 12px;\n}\n\n.totp-secret-container {\n  text-align: center;\n}\n\n.secret-box {\n  display: inline-flex;\n  padding: 8px 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  cursor: pointer;\n  user-select: none;\n  position: relative;\n  min-width: 100px;\n  justify-content: center;\n  align-items: center;\n}\n\n.secret-box.copied {\n  background-color: #28a745;\n  color: #fff;\n}\n\n.secret-content {\n  font-weight: bolder;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
