import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

import TwoFactorSetup from './TwoFactorSetup';
import PasswordStrengthBar from '../PasswordStrengthBar';
import SignupImageUpload from './SignupImageUpload';
import PrivacyPolicy from '../terms&policies/PrivacyPolicy';
import "../../assets/Signup.css";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export default function Signup({ getJWT }) {
  const history = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [created, setCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [signupError, setSignupError] = useState(false)
  const [signupValidationErrors, setSignupValidationErrors] = useState([]);
  const [resendButtonText, setResendButtonText] = useState('Email not received? Resend confirmation');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enable2FA, setEnable2FA] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isTwoFactorVerified, setIsTwoFactorVerified] = useState(false);
  const [locationPreference, setLocationPreference] = useState('');
  const [countries, setCountries] = useState([]);

  function resendConfirmationEmail() {
    setButtonDisabled(true);
    axios.post(`${process.env.REACT_APP_API_URL}/users/resend_confirmation`, { email })
      .then((response) => {
        if (response.data.status === 'ok') {
          // Update the button text
          setResendButtonText('Email confirmation resent');
        } else {
          alert('Error resending confirmation email. Please try again.');
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status === 'error') {
          alert(error.response.data.message);
        } else {
          console.log(error);
          alert('Error resending confirmation email. Please try again.');
        }
      })
  }

  function createUser(event) {
    event.preventDefault();
    event.target.reset();

    if (!email || !firstname || !password) {
      setErrorMessage('Please fill in all required fields');
      return;
    }

    let formData = new FormData();
    formData.append('user[firstname]', firstname);
    formData.append('user[lastname]', lastname);
    formData.append('user[email]', email);
    formData.append('user[password]', password);
    formData.append('user[totp_enabled]', enable2FA);
    formData.append('user[location_preference]', locationPreference);
    if (profileImage) {
      formData.append('user[profile_image]', profileImage);
    }

    setIsLoading(true);

    axios.post(`${process.env.REACT_APP_API_URL}/users`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.data.status === 'not_acceptable') {
          setSignupError(true);
          setSignupValidationErrors(response.data.errors);
        }
        if (response.data.status === 'created') {
          setCreated(true);
          setIsSignedUp(true);
          setUserId(response.data.user.id);
          setErrorMessage('');
          setSignupError(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status === 'error') {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Uh-oh! It didn't work...Make sure your rails server is running!");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    // Fetch the list of countries from your backend
    axios.get(`${process.env.REACT_APP_API_URL}/countries`)
      .then(response => {
        setCountries(response.data);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  useEffect(() => {
  }, [isLoading]);

  useEffect(() => {
    // This will force a re-render when isTwoFactorVerified changes
  }, [isTwoFactorVerified]);

  if (isSignedUp && enable2FA && !isTwoFactorVerified) {
    const jwt = getJWT();
    return jwt ? (
      <TwoFactorSetup
        user_id={userId}
        jwt={jwt}
        setIsTwoFactorVerified={setIsTwoFactorVerified}
      />
    ) : (
      <div>Loading...</div>
    );
  }

  return (
    <div className="signup-container" key={isTwoFactorVerified.toString()}>
      {isLoading ? (
        <ClipLoader loading={isLoading} size={50} color="#ff7c62" />
      ) : (
        (created && !enable2FA) || (isSignedUp && isTwoFactorVerified) ? (
          <div className="confirmation-message">
            <h2>Confirmation email sent</h2>
            <p>Please check your email to confirm your account.</p>
            <button
              className="resend-button"
              onClick={resendConfirmationEmail}
              disabled={buttonDisabled}
            >
              {resendButtonText}
            </button>
          </div>
        ) : (
          <form className="signup-form" onSubmit={createUser}>
            <h2>Signup</h2>
            <input
              type="text"
              name="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              name="firstname"
              placeholder="Firstname"
              onChange={(e) => setFirstname(e.target.value)}
            />
            <input
              type="text"
              name="lastname"
              placeholder="Lastname"
              onChange={(e) => setLastname(e.target.value)}
            />
            <select
              value={locationPreference}
              onChange={(e) => setLocationPreference(e.target.value)}
            >
              <option value="">Select Country</option>
              {countries.map(country => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="checkbox-container">
              <label htmlFor="enable2FA">Enable 2FA</label>
              <input
                type="checkbox"
                name="enable2FA"
                id="enable2FA"
                onChange={(e) => setEnable2FA(e.target.checked)}
              />
            </div>
            <PasswordStrengthBar password={password}/>
            <SignupImageUpload setImage={setProfileImage} />
            <button type="submit">Submit</button>
            {signupError && (
              <ul className="signup-error">
                {signupValidationErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
            <div className="signup-items">
              <p>{errorMessage}</p>
            </div>
            <Link className="privacy-link" to="/privacy">Privacy Policy</Link>
            <Link className="privacy-link" to="/terms">Terms of use</Link>
          </form>
      )
    )}
  </div>
);
}
