import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { ClipLoader } from "react-spinners";

import '../assets/StripeMinimal.css'

const StripeMinimal = ({
  handleSubmit,
  stripe,
  loading,
  cardOptions,
  subscriptionPrice,
  currency,
  override,
  successMessage,
  errorMessage,
  loadingMessage,
}) => {
  const [showCardDetails, setShowCardDetails] = useState(false);

  return (
    <div className='stripe-minimal'>
      <h2>Unlock the Full Power of Our Platform with Premium Subscription</h2>
      <p>Start your 7-day free trial today and gain access to exciting premium features. You'll get unlimited undo swipes, personalized content tailored just for you, priority customer support, and much more!</p>
      <p>No commitment, cancel anytime before your trial ends.</p>
      {!showCardDetails ? (
        <button className="minimalSub-btn" onClick={() => setShowCardDetails(true)}>Start My 7 Day Free Trial</button>
      ) : (
        <form data-testid="cardform" onSubmit={handleSubmit}>
          <CardElement options={cardOptions} />
          <p>Your subscription will automatically renew at the end of the trial period, and you'll be billed monthly at a rate of {subscriptionPrice ? `${currency === 'usd' ? '$' : '£'}${(subscriptionPrice / 100).toFixed(2)}` : 'Loading...'}.</p>
          <p>Remember, you're always in control. You can cancel your subscription anytime from your account settings.</p>
          <button className="minimalSub-btn" type="submit" disabled={!stripe || loading}>Start My Premium Experience</button>
        </form>
      )}
      {loading && (
        <div className="loader">
          <ClipLoader
            size={35}
            cssOverride={override}
          />
          <p>{loadingMessage}</p>
        </div>
      )}

      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
        </div>
      )}

      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default StripeMinimal;
