import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBell, faUserFriends, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as SwipeCardLogo } from '../assets/images/swipe-card-svg.svg';
import { useSelector, useDispatch } from 'react-redux';
import { clearUser } from '../redux/slices/userSlice';
import { clearToken } from '../redux/slices/tokenSlice';
import { setLoggedIn } from '../redux/slices/authSlice';
import SwitchTheme from './SwitchTheme';
import '../assets/LoggedInNavbar.css';

const Navbar = ({ notifyCount, storedTheme, setStoredTheme, setTheme, cable }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.user.userData);

  const isActive = (path) => location.pathname === path;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuOpen]);

  const handleLogout = () => {
    if (cable) cable.disconnect();
    dispatch(clearUser());
    dispatch(clearToken());
    dispatch(setLoggedIn(false));
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh_token');
    navigate('/login');
  };

  return (
    <header className="navbar">
      <div data-testid="navbar-container" className="navbar-container" ref={menuRef}>
        <nav className="navbar-nav-icons">
          <Link to="/users" className={`nav-link-icons${isActive('/users') ? ' selected' : ''}`}>
            <FontAwesomeIcon icon={faSearch} />
            <p className="nav-icon-header">Find Friends</p>
          </Link>
          <Link to="/matches" className={`nav-link-icons${isActive('/matches') ? ' selected' : ''}`}>
            <FontAwesomeIcon icon={faUserFriends} />
            <p className="nav-icon-header">Matches</p>
          </Link>
          <Link to="/deck" className={`nav-link-icons${isActive('/deck') ? ' selected' : ''}`}>
            <SwipeCardLogo className={`swipe-icon-cards${isActive('/deck') ? ' selected' : ''}`} />
            <p className="nav-icon-header">Swipe</p>
          </Link>
          <Link to="/notifications" className={`nav-link-icons${isActive('/notifications') ? ' selected' : ''}`}>
            <FontAwesomeIcon icon={faBell} />
            {notifyCount > 0 && <div className="notification-count">{notifyCount}</div>}
            <p className="nav-icon-header">Notifications</p>
          </Link>
          <div className={`nav-link-icons${menuOpen ? ' selected' : ''}`} onClick={toggleMenu}>
            <div className="menu-icon-container">
              <FontAwesomeIcon
                icon={menuOpen ? faTimes : faBars}
                className="menu-icon"
                data-testid={menuOpen ? "closebtn" : "openbtn"}
              />
            </div>
            <p className="nav-icon-header">Menu</p>
          </div>
        </nav>
        <nav data-testid='navbar-nav' className={`navbar-nav ${menuOpen ? 'show' : ''}`}>
          <div className="menu-toggle">
            <SwitchTheme setTheme={setTheme} storedTheme={storedTheme} setStoredTheme={setStoredTheme} />
          </div>
          <Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link>
          {loggedIn && user && (
            <>
              <Link to="/update" className="nav-link" onClick={() => setMenuOpen(false)}>My Account</Link>
              <Link to="/saved_content" className="nav-link" onClick={() => setMenuOpen(false)}>Saved Content</Link>
              <Link to="/friends" className="nav-link" onClick={() => setMenuOpen(false)}>Friends</Link>
              <button className="nav-link logout-btn" onClick={handleLogout}>Log Out</button>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
