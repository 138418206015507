// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-strength-bar-container {
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 3px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.password-strength-bar {
  height: 100%;
  border-radius: 3px;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.password-strength-text {
  margin-top: 0.25rem;
  font-size: 1.25rem;
  color: #333;
}

@media (max-width: 768px) {
  .password-strength-text {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/PasswordStrengthBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".password-strength-bar-container {\n  width: 100%;\n  height: 5px;\n  background-color: #e0e0e0;\n  border-radius: 3px;\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.password-strength-bar {\n  height: 100%;\n  border-radius: 3px;\n  transition: width 0.3s ease, background-color 0.3s ease;\n}\n\n.password-strength-text {\n  margin-top: 0.25rem;\n  font-size: 1.25rem;\n  color: #333;\n}\n\n@media (max-width: 768px) {\n  .password-strength-text {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
