// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-of-use-container {
  padding: 20px;
  line-height: 1.6;
  font-size: 16px;
  color: var(--text-primary);
  background-color: var(--background);
  max-width: 800px;
  margin: 0 auto;
  overflow: auto;
  height: calc(100vh - 130px);
}

.terms-of-use-container h1,
.terms-of-use-container h2 {
  color: var(--text-primary);
}

.terms-of-use-container h1 {
  margin-bottom: 20px;
}

.terms-of-use-container h2 {
  margin-top: 16px;
  margin-bottom: 4px;
}

.terms-of-use-container a {
  color: var(--text-secondary);
}
`, "",{"version":3,"sources":["webpack://./src/assets/TermsOfUse.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,mCAAmC;EACnC,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,2BAA2B;AAC7B;;AAEA;;EAEE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".terms-of-use-container {\n  padding: 20px;\n  line-height: 1.6;\n  font-size: 16px;\n  color: var(--text-primary);\n  background-color: var(--background);\n  max-width: 800px;\n  margin: 0 auto;\n  overflow: auto;\n  height: calc(100vh - 130px);\n}\n\n.terms-of-use-container h1,\n.terms-of-use-container h2 {\n  color: var(--text-primary);\n}\n\n.terms-of-use-container h1 {\n  margin-bottom: 20px;\n}\n\n.terms-of-use-container h2 {\n  margin-top: 16px;\n  margin-bottom: 4px;\n}\n\n.terms-of-use-container a {\n  color: var(--text-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
