import { useState, useEffect } from "react";

const useFetchingText = (isFetching) => {
  const [fetchingText, setFetchingText] = useState('Fetching More Items');

  useEffect(() => {
    let timeoutId;

    if (isFetching) {
      const messages = [
        "I feel the need... the need for speed. But clearly, I don’t have it!",
        "“To infinity and beyond!” - If only my loading speed was like Buzz Lightyear's",
        "“I’m king of the world!” – Me if this ever loads",
        "Searching the Matrix for your content...",
        "May the Force be with you... and my loading speed",
        "Hold on to your butts... Jurassic Loading in progress",
        "Wingardium Loadiosa! Is it working?",
        "“I’ll be back” – Me coming back with your content, eventually",
        "“Here’s looking at you, kid.” – Me to the server, hoping it hurries up",
        "Engaging warp speed! (Star Trek style, but slower)",
        "“My precious!” – What I’ll say when it finally loads",
        "Just like Inception, we need to go deeper to fetch the items!",
        "“Houston, we have a problem.” - Too slow loading speed!",
        "Life is like a box of chocolates; you never know what you’re gonna get. Just like this loading time!",
        "“A wizard is never late, nor is he early, he arrives precisely when he means to.” - Gandalf on loading times",
        "“There’s no place like home” – and there’s no time like loading time",
        "This isn’t as fast as light-speed travel in Star Wars, but it’s close...ish",
        "Keep calm and wait for the Transformers to bring your data",
        "Nobody puts Baby in a corner. Except slow loading times, apparently",
        "Beetlejuice! Beetlejuice! Beetlejuice! Did it load yet?",
        "Expecto Patronum! - Trying to cast away slow loading times.",
        "You're gonna need a bigger boat. - Me, to the server handling the data.",
        "One does not simply walk into Mordor. - Or load this page quickly, it seems.",
        "I've got a feeling we're not in Kansas anymore. - Because loading this fast is a fantasy.",
        "Yippie-ki-yay, Mother Loader! - Trying to speed things up around here.",
        "This is Sparta! - Where loading times battle for speed.",
        "I see dead people. - Me waiting for the page to finally load.",
        "E.T. phone home. - Maybe E.T. can call for faster internet.",
        "Just keep swimming. - Dory’s advice for surviving these loading times.",
        "You can't handle the speed! - Sadly, neither can this loading process.",
        "Oh, hi Mark! - Me, greeting the slow loading screen yet again.",
        "I'm going to make this load an offer it can't refuse. - Still negotiating with the server.",
        "We're not in the loading fast lane anymore, Toto. - Adjusting expectations.",
        "I'm as mad as hell, and I'm not going to take this loading time anymore! - Channeling Network's Howard Beale.",
        "Keep your friends close, but your loading screens closer. - Because you'll be seeing a lot of each other.",
        "These aren’t the droids you’re looking for... and this isn’t the speed you’re looking for either.",
        "The name's Bond, Slow Bond. - On a mission to eventually load your page.",
        "Winter is coming. - And it might arrive before this page loads.",
        "I volunteer as tribute! - Offering myself to the loading gods for speed.",
        "Wax on, wax off. - Patiently polishing our loading skills."
      ];

      const shuffledMessages = [...messages];
      for (let i = shuffledMessages.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledMessages[i], shuffledMessages[j]] = [shuffledMessages[j], shuffledMessages[i]];
      }

      let messageIndex = 0;
      timeoutId = setInterval(() => {
        messageIndex = (messageIndex + 1) % shuffledMessages.length;
        setFetchingText(shuffledMessages[messageIndex]);
      }, 3000);
    }

    return () => clearInterval(timeoutId);
  }, [isFetching]);

  return fetchingText;
};

export default useFetchingText;
