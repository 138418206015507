import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    status: false,
    endDate: null,
    cancelAtPeriodEnd: false,
  },
  reducers: {
    setSubscriptionStatus: (state, action) => {
      state.status = action.payload;
      console.log(action)
    },
    setSubscriptionEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setCancelAtPeriodEnd: (state, action) => {
      state.cancelAtPeriodEnd = action.payload;
    },
  },
});

export const {
  setSubscriptionStatus,
  setSubscriptionEndDate,
  setCancelAtPeriodEnd,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
