import React, { useState } from 'react';
import axios from 'axios';

import '../../assets/TwoFactorAuth.css'

export default function TwoFactorAuth({ user_id, onVerification }) {
  const [totpCode, setTotpCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);

function handleVerifyTotp(event) {
  event.preventDefault();

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/auth/signup_verify_totp`,
      { user_id, totp_code: totpCode },
      { withCredentials: true }
    )
    .then((response) => {
      if (response.data.status === "success") {
        onVerification(true); // Call the onVerification prop after successful verification
      } else {
        onVerification(false);
      }
    })
    .catch((error) => {
      let errorMessage;
      if (error.response) {
        errorMessage = error.response.data.message;
        setInvalidCode(errorMessage);
      } else {
        errorMessage = "Unknown error";
        console.log(error);
        onVerification(false);
      }
    });
}

  return (
    <div className="auth-verification-container">
      <form className="twofactorauth-form" onSubmit={handleVerifyTotp}>
        <h2>Verify</h2>
        <p>(Enter a one time password from your authenticator)</p>
        <input
          type="text"
          name="totpCode"
          placeholder=""
          onChange={(e) => setTotpCode(e.target.value)}
        />
        <button onClick={() => (setInvalidCode(false))} type="submit">Verify</button>
        { invalidCode &&
          <h4 className="twofactorauth-error">{invalidCode}</h4>
        }
      </form>
    </div>
  );
}
