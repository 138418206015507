import React, { useState, useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';
import { useDispatch, useSelector } from 'react-redux';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../redux/slices/subscriptionSlice';

const StripeStatusHandler = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    let subscription;

    if (loggedIn && user && token) {
      const authToken = token;
      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${authToken}`);

      subscription = cable.subscriptions.create(
        { channel: 'StripeChannel', auth_token: authToken, user: user.id },
        {
          connected: () => {
            console.log(`User: ${user.id} successfully connected to StripeChannel`);
          },
          disconnected: () => {
            console.log(`User: ${user.id} disconnected from StripeChannel`);
          },
          received: (data) => {
            console.log(`User: ${user.id} received data in StripeChannel`, data);
            dispatch(setSubscriptionStatus(data.subscription_status));
            dispatch(setSubscriptionEndDate(data.current_period_end));
            dispatch(setCancelAtPeriodEnd(data.cancel_at_period_end));
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [loggedIn, user, token, setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd]);

  return null;
};

export default StripeStatusHandler;
