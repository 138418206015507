import React, { useEffect, useState, CSSProperties } from 'react';
import axios from 'axios';
import RatingSlider from '../RatingSlider';
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from 'react-redux';

import '../../assets/DeckFiltering.css';

function DeckFiltering({
  onGenreChange,
  onRatingChange,
  plexLibraries,
  selectedPlexLibraries,
  onSelectPlexLibrary,
  selectedCollections,
  onSelectCollection
}) {
    const [genres, setGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [filter, setFilter] = useState('');
    const [ratings, setRatings] = useState([]);
    const [typedStrings, setTypedStrings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentString, setCurrentString] = useState("");
    const [stringIndex, setStringIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [plexRatings, setPlexRatings] = useState([]);
    const [plexDropdownVisible, setPlexDropdownVisible] = useState(false)
    const [collections, setCollections] = useState([]);
    const [collectionsByType, setCollectionsByType] = useState({});
    const [expandedTypes, setExpandedTypes] = useState([]);
    const [collectionsDropdownVisible, setCollectionsDropdownVisible] = useState(false);

    const token = useSelector((state) => state.token.value);

    useEffect(() => {
      // Prepare Strings for Typed
      let newStrings = genres.map(genre => `${genre.name}`).concat(ratings.map(rating => `${rating.source}`));

      // Randomize array in-place using Durstenfeld shuffle algorithm
      for (let i = newStrings.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [newStrings[i], newStrings[j]] = [newStrings[j], newStrings[i]];
      }

      setTypedStrings(newStrings);
    }, [genres, ratings]);

    useEffect(() => {
      const handleClickOutside = (event) => {
          // Check if the user clicked outside of the dropdown/input field
          if (dropdownVisible && event.target.closest(".filter-container") === null) {
              setDropdownVisible(false);
          }
      };

      // Attach the listeners to the document
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);

      // Cleanup
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
      };
    }, [dropdownVisible]); // Re-run the effect when dropdownVisible changes

    useEffect(() => {
        const fetchGenres = async () => {
            let config = {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            axios
                .get(`${process.env.REACT_APP_API_URL}/filters`, config)
                .then((response) => {
                    setGenres(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        if (token) {
            fetchGenres()
        }
    }, [token]);

    useEffect(() => {
        const genresObject = genres.reduce((acc, genre) => {
            acc[genre.id] = selectedGenres.includes(genre.id);
            return acc;
        }, {});
        onGenreChange(genresObject);
    }, [selectedGenres]);

    const handleOptionClick = (id) => {
        if (!selectedGenres.includes(id)) {
            setSelectedGenres([...selectedGenres, id]);
        } else {
            setSelectedGenres(selectedGenres.filter((genreId) => genreId !== id));
        }
    };

    const removeSelectedGenre = (id) => {
        setSelectedGenres(selectedGenres.filter((genreId) => genreId !== id));
    };

    useEffect(() => {
      const fetchRatings = async () => {
          let config = {
              headers: {
                  Authorization: "Bearer " + token,
                  "Content-Type": "application/json",
                  Accept: "application/json",
              },
          };
          axios
              .get(`${process.env.REACT_APP_API_URL}/ratings`, config)
              .then((response) => {
                  const initialRatings = response.data.map((rating) => ({
                      source: rating.source,
                      ratingValue: 0,
                  }));
                  // Add Plex rating to the list
                  initialRatings.push({ source: "Plex", ratingValue: 0 });
                  setRatings(initialRatings);
                  onRatingChange(initialRatings);
                  setLoading(false);
              })
              .catch((error) => {
                  console.error(error);
              });
      };
      if (token) {
          fetchRatings();
      }
    }, [token]);

    const handleRatingChange = (source, newValue) => {
      const updatedRatings = ratings.map((rating) =>
        rating.source === source ? { ...rating, ratingValue: newValue } : rating
      );
      setRatings(updatedRatings);
      onRatingChange(updatedRatings);
    };

    const handlePlexLibraryChange = (libraryKey) => {
      if (selectedPlexLibraries.includes(libraryKey)) {
        onSelectPlexLibrary(selectedPlexLibraries.filter((key) => key !== libraryKey));
      } else {
        onSelectPlexLibrary([...selectedPlexLibraries, libraryKey]);
      }
    };

    const override: CSSProperties = {
      borderColor: "#ff7c62",
      height: "24px",
      width: "24px"
    };

    useEffect(() => {
      if (typedStrings.length === 0) return;

      const currentWord = typedStrings[stringIndex];
      const typingSpeed = isDeleting ? 50 : 150; // Adjust speed as needed

      const timer = setTimeout(() => {
          if (!isDeleting && charIndex < currentWord.length) {
              setCurrentString(prev => prev + currentWord[charIndex]);
              setCharIndex(prev => prev + 1);
          } else if (isDeleting && charIndex > 0) {
              setCurrentString(prev => prev.slice(0, -1));
              setCharIndex(prev => prev - 1);
          } else if (charIndex === currentWord.length) {
              setIsDeleting(true);
          } else if (charIndex === 0 && isDeleting) {
              setIsDeleting(false);
              setStringIndex((prevIndex) => (prevIndex + 1) % typedStrings.length);
          }
      }, typingSpeed);

      return () => clearTimeout(timer);
    }, [charIndex, stringIndex, typedStrings, isDeleting]);

    const fetchCollections = async () => {
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const libraryKeys = plexLibraries.map((library) => library.key);

      const apiUrl = `${process.env.REACT_APP_API_URL}/plex_media/collections?library_keys=${libraryKeys.join(',')}`;

      axios
        .get(apiUrl, config)
        .then((response) => {
          const groupedCollections = response.data.reduce((acc, collection) => {
            const capitalizedType = collection.type.charAt(0).toUpperCase() + collection.type.slice(1);
            if (!acc[capitalizedType]) {
              acc[capitalizedType] = [];
            }
            acc[capitalizedType].push(collection);
            return acc;
          }, {});
          setCollectionsByType(groupedCollections);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const handleCollectionChange = (collectionKey) => {
      if (selectedCollections.includes(collectionKey)) {
        onSelectCollection(selectedCollections.filter((key) => key !== collectionKey));
      } else {
        onSelectCollection([...selectedCollections, collectionKey]);
      }
    };

    const toggleTypeExpansion = (type) => {
      if (expandedTypes.includes(type)) {
        setExpandedTypes(expandedTypes.filter((t) => t !== type));
      } else {
        setExpandedTypes([...expandedTypes, type]);
      }
    };

    useEffect(() => {
      if (token && plexLibraries.length > 0) {
        fetchCollections();
      }
    }, [token, plexLibraries]);

    return (
      <div className="filter-container">
          <div className="input-field" data-testid="inputfieldbox" onClick={() => setDropdownVisible(!dropdownVisible)}>
              {loading ? (
                  <ClipLoader
                      loading={true}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      cssOverride={override}
                  />
              ) : selectedGenres.length === 0 && !dropdownVisible && (
                  <div className="typed-strings">
                    <p>Filter by: {currentString}</p>
                  </div>
              )}
              {selectedGenres.map((id) => {
                  const genre = genres.find((genre) => genre.id === id);
                  return genre ? (
                      <div key={id} className="selected-genre">
                          {genre.name}
                          <span className="remove-genre" onClick={(e) => { e.stopPropagation(); removeSelectedGenre(id); }}>&times;</span>
                      </div>
                  ) : null;
              })}
          </div>
          {dropdownVisible && (
              <div className="scrollable-container">
                  <div className="dropdown" data-testid="dropdown">
                      <input type="text" className="filter-input" placeholder="Filter..." onChange={(e) => setFilter(e.target.value)} />
                      {genres
                          .filter((genre) => genre.name.toLowerCase().includes(filter.toLowerCase()))
                          .map((genre) => (
                              <div key={genre.id} className="dropdown-option" onClick={() => handleOptionClick(genre.id)}>
                                  <input type="checkbox" checked={selectedGenres.includes(genre.id)} readOnly /> {genre.name}
                              </div>
                          ))}
                  </div>
                  <div className="plex-library-selector">
                    <div className="plex-library-dropdown" onClick={() => setPlexDropdownVisible(!plexDropdownVisible)}>
                      <span>{selectedPlexLibraries.length > 0 ? `Selected Libraries (${selectedPlexLibraries.length})` : 'Select Plex Libraries'}</span>
                      <i className={`arrow ${plexDropdownVisible ? 'up' : 'down'}`}></i>
                    </div>
                    {plexDropdownVisible && (
                      <div className="plex-library-options">
                        {plexLibraries.map((library) => (
                          <div key={library.key} className="plex-library-option">
                            <input
                              type="checkbox"
                              id={`plex-library-${library.key}`}
                              checked={selectedPlexLibraries.includes(library.key)}
                              onChange={() => handlePlexLibraryChange(library.key)}
                            />
                            <label htmlFor={`plex-library-${library.key}`}>{library.title}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="collections-container">
                    {Object.entries(collectionsByType).map(([type, collections]) => (
                      <div key={type} className="collection-type">
                        <div className="collection-type-dropdown" onClick={() => toggleTypeExpansion(type)}>
                          <span>Plex {type} Collections</span>
                          <i className={`arrow ${expandedTypes.includes(type) ? 'up' : 'down'}`}></i>
                        </div>
                        {expandedTypes.includes(type) && (
                          <div className="collection-type-options">
                            {collections.map((collection) => (
                              <div key={collection.key} className="collection-option">
                                <input
                                  type="checkbox"
                                  id={`collection-${collection.key}`}
                                  checked={selectedCollections.includes(collection.key)}
                                  onChange={() => handleCollectionChange(collection.key)}
                                />
                                <label htmlFor={`collection-${collection.key}`}>{collection.title}</label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="ratings-container" data-testid="rating-slider">
                      {ratings.map((rating) => (
                          <div className="rating-slider" data-testid={`rating-slider-${rating.source}`}  key={rating.source}>
                              <RatingSlider
                                  rating={rating}
                                  onRatingChange={handleRatingChange}
                              />
                          </div>
                      ))}
                  </div>
              </div>
          )}
      </div>
    );
}
export default DeckFiltering;
