// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --app-height: 100%;
  --background: white;
  --secondary-background: #333;
  --menu-background: #f5f5f5;
  --background-consistent: #f5f5f5;
  --text-primary: black;
  --text-secondary: white;
  --text-consistent: black;
  --theme: black;
  --secondary-theme: #ff7c62;
  --tab-background: #e1e1e1;
  --theme-consistent: #ff7c62;
}

[data-theme='dark'] {
  --background: #333;
  --secondary-background: white;
  --background-consistent: #f5f5f5;
  --menu-background: #333;
  --tab-background: black;
  --text-primary: white;
  --text-secondary: black;
  --text-consistent: black;
  --theme: #ff7c62;
  --secondary-theme: white;
  --theme-consistent: #ff7c62;
}

.App {
  text-align: center;
  overflow: hidden;
}

body {
  background-color: var(--background);
}



.home-text {
  position: absolute;
  top: 20px;
}

.modal-dialog {
  width: 80vw !important;
}

.alert-count {
  background: red;
  color: white;
  font-weight: bold;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,4BAA4B;EAC5B,0BAA0B;EAC1B,gCAAgC;EAChC,qBAAqB;EACrB,uBAAuB;EACvB,wBAAwB;EACxB,cAAc;EACd,0BAA0B;EAC1B,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,gCAAgC;EAChC,uBAAuB;EACvB,uBAAuB;EACvB,qBAAqB;EACrB,uBAAuB;EACvB,wBAAwB;EACxB,gBAAgB;EAChB,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC;;;;AAIA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[":root {\n  --app-height: 100%;\n  --background: white;\n  --secondary-background: #333;\n  --menu-background: #f5f5f5;\n  --background-consistent: #f5f5f5;\n  --text-primary: black;\n  --text-secondary: white;\n  --text-consistent: black;\n  --theme: black;\n  --secondary-theme: #ff7c62;\n  --tab-background: #e1e1e1;\n  --theme-consistent: #ff7c62;\n}\n\n[data-theme='dark'] {\n  --background: #333;\n  --secondary-background: white;\n  --background-consistent: #f5f5f5;\n  --menu-background: #333;\n  --tab-background: black;\n  --text-primary: white;\n  --text-secondary: black;\n  --text-consistent: black;\n  --theme: #ff7c62;\n  --secondary-theme: white;\n  --theme-consistent: #ff7c62;\n}\n\n.App {\n  text-align: center;\n  overflow: hidden;\n}\n\nbody {\n  background-color: var(--background);\n}\n\n\n\n.home-text {\n  position: absolute;\n  top: 20px;\n}\n\n.modal-dialog {\n  width: 80vw !important;\n}\n\n.alert-count {\n  background: red;\n  color: white;\n  font-weight: bold;\n  min-width: 24px;\n  min-height: 24px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
