// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-alert {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: var(--menu-background);
  color: var(--theme-consistent);
  text-align: center;
  display: none;
  overflow: hidden;
  margin: 8px;
  border-radius: 16px;
  box-shadow: var(--theme-consistent) 0px 0px 5px 0px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  z-index: 9999999;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* This line makes it fade */
}

.notification-alert a {
  text-decoration: none;
  color: var(--theme-consistent);
}

.notification-alert.show {
  display: block;
}

.notification-alert.hide {
  opacity: 0;
}

.scrolling-text {
  white-space: nowrap;
  position: relative;
  animation: scrolling 8s linear infinite;
}

@keyframes scrolling {
  0% { left: 100%; }
  100% { left: -100%; }
}

.countdown {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8px;
  border-radius: 16px;
  background-color: var(--theme-consistent);
  animation: countdown 8s linear forwards;
}

@keyframes countdown {
  0% { width: 100%; }
  100% { width: 0; }
}
`, "",{"version":3,"sources":["webpack://./src/assets/NotificationAlert.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,OAAO;EACP,QAAQ;EACR,aAAa;EACb,wCAAwC;EACxC,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,mDAAmD;EACnD,2CAA2C;EAC3C,gBAAgB;EAChB,UAAU;EACV,oCAAoC,EAAE,4BAA4B;AACpE;;AAEA;EACE,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,KAAK,UAAU,EAAE;EACjB,OAAO,WAAW,EAAE;AACtB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,mBAAmB;EACnB,yCAAyC;EACzC,uCAAuC;AACzC;;AAEA;EACE,KAAK,WAAW,EAAE;EAClB,OAAO,QAAQ,EAAE;AACnB","sourcesContent":[".notification-alert {\n  position: fixed;\n  bottom: 70px;\n  left: 0;\n  right: 0;\n  padding: 10px;\n  background-color: var(--menu-background);\n  color: var(--theme-consistent);\n  text-align: center;\n  display: none;\n  overflow: hidden;\n  margin: 8px;\n  border-radius: 16px;\n  box-shadow: var(--theme-consistent) 0px 0px 5px 0px;\n  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);\n  z-index: 9999999;\n  opacity: 1;\n  transition: opacity 0.3s ease-in-out; /* This line makes it fade */\n}\n\n.notification-alert a {\n  text-decoration: none;\n  color: var(--theme-consistent);\n}\n\n.notification-alert.show {\n  display: block;\n}\n\n.notification-alert.hide {\n  opacity: 0;\n}\n\n.scrolling-text {\n  white-space: nowrap;\n  position: relative;\n  animation: scrolling 8s linear infinite;\n}\n\n@keyframes scrolling {\n  0% { left: 100%; }\n  100% { left: -100%; }\n}\n\n.countdown {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  height: 8px;\n  border-radius: 16px;\n  background-color: var(--theme-consistent);\n  animation: countdown 8s linear forwards;\n}\n\n@keyframes countdown {\n  0% { width: 100%; }\n  100% { width: 0; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
