import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import '../assets/NotificationList.css';
import '../assets/NotificationCards.css';

import NotificationCard from './NotificationCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from "react-spinners/BeatLoader";
import defaultProfileImage from '../assets/images/dummy-pic.jpg';

const Notifications = ({ setNotifyCount, notifyCount, shouldRefreshRequests, setShouldRefreshRequests }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  // Updated fetchPendingRequests function
  const fetchPendingRequests = async (pageNumber = page + 1) => {
    if (user && token && !loading) {
      try {
        setLoading(true);

        const config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/sentinvites/${user.id}?page=${pageNumber}&per_page=10`,
          config
        );


        setPendingRequests((prevPendingRequests) => [
          ...prevPendingRequests,
          ...response.data.pending_users,
        ]);
        setPage((prevPage) => prevPage + 1);

        if (response.data.pending_users.length === 0) {
          setHasMore(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    }
  };

  const AcceptFriendRequest = async (data) => {
  const id = data.invite_id;
  const url = `${process.env.REACT_APP_API_URL}/invitations/${id}`

    axios.put(url, {
    }, {
      headers: {
        'Authorization': `Basic ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    .then((response) => {
      // remove the accepted invitation from the pendingRequests state so it won't be rendered anymore
      const updatedPendingRequests = pendingRequests.filter((r) => r.invite_id !== id);
      setPendingRequests(updatedPendingRequests);
      setNotifyCount(notifyCount - 1)
    })
  }

  const RejectFriendRequest = async (invite) => {
    const id = invite.invite_id;
    const url = `${process.env.REACT_APP_API_URL}/invitations/${id}`

    const data = {
      invitation_id: id,
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    axios.delete(url, { headers, params: data })
      .then(() => {
        const updatedPendingRequests = pendingRequests.filter((r) => r.invite_id !== id);
      setPendingRequests(updatedPendingRequests);
      setNotifyCount(notifyCount - 1)
    })
  }

  // Use effect hook to fetch pending friend requests when the component mounts if the user is logged in
  useEffect(() => {
    if (token && user) {
      setShouldRefreshRequests(true);
    }
  }, [token, user]);

  useEffect(() => {
    if (shouldRefreshRequests) {
      // Reset page to 1
      setPage(1);

      // Clear the pendingRequests array
      setPendingRequests([]);

      // Make sure that hasMore is set to true
      setHasMore(true);

      // Fetch the pending requests starting from page 1
      fetchPendingRequests(1);

      // Reset the state back to false
      setShouldRefreshRequests(false);
    }
  }, [shouldRefreshRequests]);

  const requests = pendingRequests.map((data, i) => (
    <div className="requestsNotificationCardContainer" key={`${data.invite_id}-${i}`}>
      <NotificationCard imageUrl={data.profile_image_url ? data.profile_image_url : defaultProfileImage} id={data.user.id} firstname={data.user.firstname} lastname={data.user.lastname} />
      <button data-testid={`acceptBtn-${i}`} className="acceptBtn" onClick={() => AcceptFriendRequest(data)}>Accept Request</button>
      <button data-testid={`rejectBtn-${i}`} className="rejectBtn" onClick={() => RejectFriendRequest(data)}>Reject Request</button>
    </div>
  ));

  return (
    <div className='scrollContainer'>
      <div className='notificationHeader'>
        <h1 className='notificationTitle'>Notifications</h1>
        <div className='searchContainer'>
        {isFetching ? (
          <div className="initial-loader-container">
            <ClipLoader
              loading={true}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#ff7c62"
            />
          </div>
        ) : pendingRequests.length > 0 ? (
            <div id="scrollableDiv" style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
              <InfiniteScroll
                dataLength={pendingRequests.length}
                next={() => fetchPendingRequests(page + 1)}
                hasMore={hasMore}
                loader={
                  <div className={`request-loader-container${loading ? ' show' : ''}`}>
                    <ClipLoader
                      loading={loading}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      color="#ff7c62"
                    />
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: "center", marginBottom: "60px" }}>
                    <b>There are no more pending friend requests</b>
                  </p>
                }
                scrollableTarget="scrollableDiv"
              >
                {requests}
              </InfiniteScroll>
            </div>
          ) : (
            <div className='noPending-message'>You have no pending friend requests.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
