import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import PasswordStrengthBar from '../PasswordStrengthBar';
import UpdateTwoFactorAuth from './UpdateTwoFactorAuth';
import ImageUpload from './ImageUpload';
import UserPreference from '../UserPreference';
import StripeSubscription from './StripeSubscription';
import '../../assets/update.css';
import tmdb_logo from '../../assets/images/tmdb_logo.svg';
import { Link } from 'react-router-dom';
import { setUser } from '../../redux/slices/userSlice';

function Update({ logOut }) {
  const token = useSelector((state) => state.token.value);
  const user = useSelector((state) => state.user.userData);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const subscriptionStatus = useSelector((state) => state.subscription.status);
  const subscriptionEndDate = useSelector((state) => state.subscription.endDate);
  const cancelAtPeriodEnd = useSelector((state) => state.subscription.cancelAtPeriodEnd);

  const [firstname, setfirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [updateError, setUpdateError] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [updating, setUpdating] = useState(false);
  const [qrCodeSVG, setQrCodeSVG] = useState(null);
  const [showQrCode, setShowQrCode] = useState(true);
  const [is2FAEnabled, setIs2FAEnabled] = useState(null);
  const [show2FACodeInput, setShow2FACodeInput] = useState(false);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [totpSecret, setTotpSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [locationPreference, setLocationPreference] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [plexAddress, setPlexAddress] = useState('');
  const [plexPort, setPlexPort] = useState('');
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(true);
  const [isSecurityOpen, setIsSecurityOpen] = useState(false);
  const [isPlexOpen, setIsPlexOpen] = useState(false);
  const [countries, setCountries] = useState([]);


  const updateUser = async (newLocationPreference = null) => {

    let updatedUser = {};
    if (firstname) updatedUser.firstname = firstname;
    if (lastname) updatedUser.lastname = lastname;
    if (email) updatedUser.email = email;
    if (currentPassword) updatedUser.current_password = currentPassword;
    if (password) updatedUser.password = password;
    if (passwordConfirmation) updatedUser.password_confirmation = passwordConfirmation;
    if (newLocationPreference || locationPreference) updatedUser.location_preference = newLocationPreference || locationPreference;
    if (plexAddress) updatedUser.plex_address = plexAddress;
    if (plexPort !== '') updatedUser.plex_port = plexPort; // Check if plexPort is not an empty string
    else updatedUser.plex_port = null; // Set plexPort to null if it's an empty string


    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    setUpdating(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/users/${user.id}`, { user: updatedUser }, config)
      .then((response) => {
        if (response.data.status === "update_failed") {
          setUpdateError(true);
          setValidationErrors(response.data.error);
        } else if (response.data.status === "success") {
          setSuccessMessage("UPDATE SUCCESS");
          setUpdateError(false);
          setValidationErrors([]);
          window.location = "/update";
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const enable2FA = async () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/users/${user.id}/enable_totp`, {}, config)
      .then((response) => {
        if (response.data.qrcode) {
          setQrCodeSVG(response.data.qrcode);
          setTotpSecret(response.data.totp_secret); // Store the secret in the state
          setShow2FACodeInput(true);
        } else {
          alert("1 Error enabling 2FA. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("2 Error enabling 2FA. Please try again.");
      });
  };

  const handle2FAVerification = async (verified, status) => {
    if (verified) {
      setIs2FAEnabled(true);
      setShow2FACodeInput(false);
      setVerificationSuccessful(true);
      setShowQrCode(false);
      const timer = setTimeout(() => {
        setVerificationSuccessful(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else if (status === "error") {
      alert("3 Error verifying 2FA code. Please try again.");
    }
  };


  const toggle2FA = async () => {
    if (is2FAEnabled) {
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (is2FAEnabled) {
        if (window.confirm("Are you sure you want to disable 2FA?")) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/users/${user.id}/disable_totp`, {}, config)
            .then((response) => {
              if (response.data.message === "2FA disabled") {
                setIs2FAEnabled(false);
                setQrCodeSVG(null);
              } else {
                alert("4 Error updating 2FA settings. Please try again.");
              }
            })
            .catch((err) => {
              console.log(err);
              alert("5 Error updating 2FA settings. Please try again.");
            });
        }
      }
    } else {
      enable2FA();
    }
  };

  const deleteAccount = async () => {
    var question = window.confirm("Are you sure you want to leave us?");
    if (question && user && user.id) {
      axios.delete(`${process.env.REACT_APP_API_URL}/users/${user.id}`,
        {
          headers:
          {
            'Authorization': 'Bearer ' + token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          if (response.status === 200) {
            // redirect to home page
            localStorage.token = '';
            logOut()
            window.location = "/";
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Error deleting account. Please try again.");
        });
    }
  }

  const dispatch = useDispatch();

  const fetchUserDetails = async () => {
    if (loggedIn && user && user.id && token) {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (response.data && response.data.user) {
          dispatch(setUser(response.data.user));
          setIs2FAEnabled(response.data.user.totp_enabled);
          setLocationPreference(response.data.user.location_preference);

          if (response.data.user.plex_id) {
            setPlexAddress(response.data.user.plex_address);
            setPlexPort(response.data.user.plex_port);
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchUserDetails();
    setLoading(false);
  }, [loggedIn, is2FAEnabled]);

  useEffect(() => {
    // Fetch the list of countries from your backend
    axios.get(`${process.env.REACT_APP_API_URL}/countries`, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(response => {
        setCountries(response.data);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const saveUserPreferences = async (selectedProviders) => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_preferences`,
        {
          user_preference: {
            streaming_providers: selectedProviders.map((provider) => ({
              provider_name: provider.provider_name,
              logo_path: provider.logo_path,
            })),
          },
        },
        config
      );

      if (response.data.status === "success") {
      } else {
        console.error("Failed to save user preferences:", response.data.errors);
      }
    } catch (error) {
      console.error("Error saving user preferences:", error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSection = (section) => {
    switch (section) {
      case 'personalInfo':
        setIsPersonalInfoOpen(!isPersonalInfoOpen);
        break;
      case 'security':
        setIsSecurityOpen(!isSecurityOpen);
        break;
      case 'plex':
        setIsPlexOpen(!isPlexOpen);
        break;
      default:
        break;
    }
  };

  const imageUrl = user && user.profile_image_url ? user.profile_image_url : require('../../assets/images/dummy-pic.jpg');

  return (
    <div className="update-container">
      {user ? (
        <>
          <div className="update-items">
            <h2>Update {user.firstname}'s Information</h2>
            <ImageUpload user={user} token={token} />
            <p>Current email: {user.email}</p>
            <p>Current firstname: {user.firstname}</p>
            <p>Current lastname: {user.lastname}</p>
          </div>
          <form className="update-form">
            <div className="section-header" onClick={() => toggleSection('personalInfo')}>
              <h3>Personal Information</h3>
              <span>{isPersonalInfoOpen ? '▲' : '▼'}</span>
            </div>
            {isPersonalInfoOpen && (
              <div className="section-content">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="New Email"
                />
                <input
                  type="text"
                  value={firstname}
                  onChange={(e) => setfirstname(e.target.value)}
                  placeholder="New Firstname"
                />
                <input
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="New Lastname"
                />
                <select
                  value={locationPreference}
                  onChange={async (e) => {
                    setLocationPreference(e.target.value);
                    await updateUser(e.target.value);
                  }}
                >
                  <option value="">Select Country</option>
                  {countries.map(country => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="section-header" onClick={() => toggleSection('security')}>
              <h3>Security Settings</h3>
              <span>{isSecurityOpen ? '▲' : '▼'}</span>
            </div>
            {isSecurityOpen && (
              <div className="section-content">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                />
                <input
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  placeholder="Confirm Password"
                />
                <PasswordStrengthBar password={password} />
                <button className="btn-toggle-2fa" onClick={toggle2FA} type="button">
                  {loading ? 'Loading...' : is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
                </button>
              </div>
            )}
            {user && user.plex_id && (
              <>
                <div className="section-header" onClick={() => toggleSection('plex')}>
                  <h3>Plex Settings</h3>
                  <span>{isPlexOpen ? '▲' : '▼'}</span>
                </div>
                {isPlexOpen && (
                  <div className="section-content">
                    <input
                      type="text"
                      value={plexAddress}
                      onChange={(e) => setPlexAddress(e.target.value)}
                      placeholder="Plex Address"
                    />
                    <input
                      type="text"
                      value={plexPort}
                      onChange={(e) => setPlexPort(e.target.value)}
                      placeholder="Plex Port (optional)"
                    />
                  </div>
                )}
              </>
            )}
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter Current Password to update"
            />
            <UserPreference user={user} token={token} saveUserPreferences={saveUserPreferences} />
            <button
              onClick={(e) => {
                e.preventDefault();
                updateUser();
              }}
              type="submit"
              disabled={updating}
              className={updating ? 'updating' : ''}
            >
              {updating ? 'Updating...' : 'UPDATE'}
            </button>
            {updateError && (
              <ul className="update-error">
                {validationErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
          </form>
          <StripeSubscription
            token={token}
            cancelAtPeriodEnd={cancelAtPeriodEnd}
            subscriptionStatus={subscriptionStatus}
            subscriptionEndDate={subscriptionEndDate}
          />
          <div className="delete-container">
            <button onClick={toggleDropdown}>Terms &amp; Policies</button>
            {dropdownOpen && (
              <div className="terms-dropdown-content">
                <Link className="update-terms-link" to="/privacy">
                  Privacy Policy
                </Link>
                &nbsp;
                <Link className="update-terms-link" to="/terms">
                  Terms of Use
                </Link>
              </div>
            )}
            <button className="btn-delete-account" onClick={deleteAccount} type="submit">
              DELETE ACCOUNT
            </button>
          </div>
          <div className="credits-container">
            <p>This product uses the TMDb API but is not endorsed or certified by TMDb.</p>
            <img src={tmdb_logo} alt="The Movie Database Logo" />
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default Update;
