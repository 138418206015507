import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import '../../assets/HeartButton.css';
import { useSelector } from 'react-redux';

const HeartButton = ({ contentId, source }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);

  const [hearted, setHearted] = useState(false);

  const handleClick = async () => {
    setHearted(true); // This line runs whenever the button is clicked
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/users/${user.id}/saved_contents`,
        {
          content_id: contentId,
          source: source  // Passing the source to the backend
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Failed to save content', error);
    }
  };

  return (
    <FontAwesomeIcon
      icon={faHeart}
      onClick={handleClick}
      className={`heart-icon ${hearted ? 'hearted' : ''}`}
      data-testid='heartbtn'
    />
  );
};

export default HeartButton;
