// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-file-upload-input {
  display: none;
}

.signup-file-upload-label {
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: var(--theme-consistent);
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
}

.signup-image-upload-success {
  text-align: center;
  color: #4CAF50;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .signup-file-upload-label {
    font-size: 16px;
    padding: 8px;
    margin: 8px 0;
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/SignupImageUpload.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,yCAAyC;EACzC,cAAc;EACd,YAAY;EACZ,sBAAsB;EACtB,0CAA0C;EAC1C,2CAA2C;EAC3C,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE;IACE,eAAe;IACf,YAAY;IACZ,aAAa;IACb,YAAY;EACd;AACF","sourcesContent":[".signup-file-upload-input {\n  display: none;\n}\n\n.signup-file-upload-label {\n  width: 100%;\n  padding: 0.5rem;\n  margin-top: 1rem;\n  background-color: var(--theme-consistent);\n  color: #ffffff;\n  border: none;\n  border-radius: 0.25rem;\n  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);\n  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  text-align: center;\n}\n\n.signup-image-upload-success {\n  text-align: center;\n  color: #4CAF50;\n  font-size: 1.2rem;\n}\n\n@media (max-width: 768px) {\n  .signup-file-upload-label {\n    font-size: 16px;\n    padding: 8px;\n    margin: 8px 0;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
