import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedGenres, setSelectedProviders } from '../redux/slices/filterSlice';
import "../assets/FilterDropdown.css";
import justwatch_logo from "../assets/images/JustWatch-logo.webp"

const FilterDropdown = ({
  showDropdown,
  closeDropdown,
  originalLikedContent,
  onGenresChange,
  onProvidersChange,
  user
}) => {
  const dispatch = useDispatch();
  const { selectedGenres, selectedProviders } = useSelector(state => state.filter);

  const [genres, setGenres] = useState([]);
  const [providers, setProviders] = useState([]);
  const [animation, setAnimation] = useState("");

  const handleClose = () => {
    setAnimation("out-right");
    setTimeout(() => {
      closeDropdown();
    }, 300);
  };

  useEffect(() => {
    if (showDropdown) {
      setAnimation("in-right");
    }
  }, [showDropdown]);

  const locationPreference = user?.location_preference?.toLowerCase()

  const uniqueProviders = useMemo(() => {
    const providerMap = new Map();
    originalLikedContent.forEach(content => {
      const countryInfo = content.streaming_info?.[locationPreference]?.tmdb_providers;
      if (countryInfo) {
        ['buy', 'flatrate', 'rent'].forEach(type => {
          countryInfo[type]?.forEach(provider => {
            if (!providerMap.has(provider.provider_id)) {
              providerMap.set(provider.provider_id, {
                ...provider,
                logo_path: `https://image.tmdb.org/t/p/original${provider.logo_path}`
              });
            }
          });
        });
      }
    });
    return Array.from(providerMap.values());
  }, [originalLikedContent, locationPreference]);

  useEffect(() => {
    if (originalLikedContent && originalLikedContent.length > 0) {
      const newGenres = [
        ...new Set(originalLikedContent.flatMap((content) => content.genres)),
      ];
      setGenres(newGenres);
      setProviders(uniqueProviders);
    }
  }, [originalLikedContent, uniqueProviders]);

  const onGenreChange = (genre) => {
    const updatedGenres = selectedGenres.includes(genre)
      ? selectedGenres.filter(g => g !== genre)
      : [...selectedGenres, genre];
    dispatch(setSelectedGenres(updatedGenres));
    onGenresChange(updatedGenres);
  };

  const onProviderChange = (providerId) => {
    const provider = providers.find(p => p.provider_id.toString() === providerId);
    const providerName = provider?.provider_name.toLowerCase();
    const updatedProviders = selectedProviders.includes(providerName)
      ? selectedProviders.filter(p => p !== providerName)
      : [...selectedProviders, providerName];
    dispatch(setSelectedProviders(updatedProviders));
    onProvidersChange(updatedProviders);
  };

  return (
    <div className={`fd-filter-dropdown slide-${animation}`}>
      <div className="fd-header">
        <h3>Filter Options</h3>
        <button className="fd-close-button" onClick={handleClose}>×</button>
      </div>
      <div className="fd-content">
        <div className="fd-section">
          <h4>Genres</h4>
          <div className="fd-genre-buttons">
            {genres.map((genre) => (
              <button
                key={genre}
                className={`fd-genre-button ${selectedGenres.includes(genre) ? 'fd-selected' : ''}`}
                onClick={() => onGenreChange(genre)}
              >
                {genre}
              </button>
            ))}
          </div>
        </div>
        <div className="fd-section">
          <h4>Providers</h4>
          <div className="fd-provider-grid">
            {providers.map((provider) => (
              <button
                key={provider.provider_id}
                className={`fd-provider-button ${selectedProviders.includes(provider.provider_name.toLowerCase()) ? 'fd-selected' : ''}`}
                onClick={() => onProviderChange(provider.provider_id.toString())}
              >
                <img
                  src={provider.logo_path}
                  alt={`${provider.provider_name} logo`}
                  className="fd-provider-logo"
                />
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="fd-credits-container">
        <p>Provider Info sourced from JustWatch</p>
        <img className="fd-justwatch-logo" src={justwatch_logo} alt="JustWatch Logo" />
      </div>
    </div>
  );
}

export default FilterDropdown;
